import * as React from "react";

import MUIRichTextEditor from "mui-rte";
import draftToHtml from 'draftjs-to-html';
import strings from '../../localization/strings';
import EventUtils from "../../utils/event-utils";
import createMuiTheme from "../../theme/default-theme";
import styles from "../../styles/generic/localized-input";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { Paper, ThemeProvider, Typography, withStyles, WithStyles } from "@material-ui/core";
import { CustomStyles, Localizations, LocalizedStrings, LocalizedValues } from "../../types";

const MUIRichTextEditorTheme = createMuiTheme;

Object.assign(MUIRichTextEditorTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        backgroundColor: "#fff",
      },
      container: { },
      editor: {
        padding: MUIRichTextEditorTheme.spacing(2),
        paddingTop: 0,
      },
      toolbar: {

      },
      placeHolder: {
        padding: MUIRichTextEditorTheme.spacing(2),
        paddingTop: 0,
      },
      anchorLink: {

      }
    }
  }
});

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  localizations: Localizations;
  localizedValues: LocalizedValues;
  localizedStrings: LocalizedStrings;
  onChange: (locale: string, value: string) => void;
}

/**
 * Interface describing component state
 */
interface State {
  initialValues: string[];
}

/**
 * Localized textfield component
 */
class LocalizedTextField extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      initialValues: [
        JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()))
      ]
    };
  }

  /**
   * Component did mount
   */
  public componentDidMount = () => {
    const { localizedValues } = this.props;
    const localizationKeys = EventUtils.getLocalizationKeys();

    this.setState({
      initialValues: localizationKeys.map((key) => {
        const value = localizedValues[key as keyof object];
        const markup = `${ value || "<p></p>" }`;
        const contentHTML = convertFromHTML(markup);
        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
        return JSON.stringify(convertToRaw(state));
      })
    });
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderLocalizedTextFields() }
      </div>
    );
  }

  /**
   * Method for rendering localized inputs
   */
  private renderLocalizedTextFields = () => {
    const { customStyles, classes, localizations, localizedStrings } = this.props;
    const { initialValues } = this.state;
    const localizationKeys = EventUtils.getLocalizationKeys();

    return localizationKeys.map((key: string, index: number) => {

      if (!localizations[key as keyof Localizations]) {
        return null;
      }

      const localizedString = localizedStrings[key as keyof object];

      return (
        <React.Fragment key={ index }>
          <Typography 
            className={ classes.title }
            style={ customStyles?.title }
            variant="subtitle1"
          >
            { localizedString }
          </Typography>
          <Paper 
            className={ classes.richText }
            style={ customStyles?.richText }
          >
            <ThemeProvider theme={ MUIRichTextEditorTheme }>
              <MUIRichTextEditor
                defaultValue={ initialValues[index] }
                onChange={ this.onChange(key) }
                label={ strings.eventForm.descriptionText }
                controls={["title", "bold", "italic", "underline", "undo", "redo", "link", "numberList", "bulletList"]}
              />
            </ThemeProvider>
          </Paper>
        </React.Fragment>
      );
    });
  }

  /**
   * Method for localized textfield on change handler
   * 
   * @param key key
   * @param draft draft
   */
  private onChange = (key: string) => (draft: any) => {
    const { onChange, localizedValues } = this.props;
    const currentValue = localizedValues[key as keyof LocalizedValues];
    const rawContentState = convertToRaw(draft.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    if (currentValue !== markup) {
      onChange(key, markup);
    }
  }

}

const Styled = withStyles(styles)(LocalizedTextField);
const CustomStyled = withCustomStyles("generic/localized-richtextfield")(Styled);

export default CustomStyled;
