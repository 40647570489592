import * as React from 'react';

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import strings from "../../localization/strings";
import styles from "../../styles/generic/some-links";
import SimpleReactValidator from "simple-react-validator";
import { NullableToken, CustomStyles } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { EVENT_LINK_NAME } from "../../constants/action-types";
import { withStyles, WithStyles, TextField } from "@material-ui/core";

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  validator: SimpleReactValidator;
  externalLinks: { [key: string]: string };
  onChange: (key: string, value: string) => void;
}

/**
 * Component state
 */
interface State { }

/**
 * Event links component 
 */
class EventLinks extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { }
  }

  /**
   * Component render
   */
  public render = () => {
    const { customStyles, classes, externalLinks } = this.props;

    return (
      <>
        {
          EVENT_LINK_NAME.map((linkName, index) => {
            const link = externalLinks[linkName];

            if (linkName === "Instagram HashTag") {
              return (
                <div
                  key={ index }
                  className={ classes.input }
                  style={ customStyles?.input }
                >
                  <TextField
                    label={ linkName }
                    value={ this.parseHashtag(link) }
                    onChange={ this.onChangeHashtag(linkName) }
                    placeholder={ strings.eventForm.eventLinks[linkName as keyof object] }
                  />
                </div>
              );
            }

            return (
              <div
                key={ index }
                className={ classes.input }
                style={ customStyles?.input }
              >
                <TextField
                  label={ linkName }
                  value={ link }
                  onChange={ this.onChange(linkName) }
                  placeholder={ strings.eventForm.eventLinks[linkName as keyof object] }
                />
                { this.renderValidatorMessage(`externalLinks${ index }`, link) }
              </div>
            );
          })
        }
      </>
    );
  }

  /**
   * Method for rendering validator message
   *
   * @param field field
   * @param value value
   */
  private renderValidatorMessage = (field: string, value: string) => {
    const { validator } = this.props;

    if (value && !/^https?:\/\//g.test(value)) {
      return validator.message(field, `https://${ value }`, "url");
    }

    return validator.message(field, value, "url");
  }

  /**
   * Method for changing event external links
   *
   * @param key key
   * @param event event object
   */
  private onChange = (key: string) => (event: React.ChangeEvent<any>) => {
    const { onChange } = this.props;
    const value = event.target.value;
    onChange(key, value);
  }

  /**
   * Method for parsing hashtag
   *
   * @param link link with hashtag
   */
  private parseHashtag = (link: string) => {
    const decoded = decodeURIComponent(link);
    const stripped = decoded.replace(/^\/|\/$/g, "");
    const splitted = stripped.split("/");
    return splitted.length ? splitted.pop() : "";
  }

  /**
   * Method for changing hashtag
   *
   * @param key key
   * @param event event object
   */
  private onChangeHashtag = (key: string) => (event: React.ChangeEvent<any>) => {
    const { onChange } = this.props;
    const value = event.target.value;
    const link = value ? `https://www.instagram.com/explore/tags/${ encodeURIComponent(value) }/` : "";
    onChange(key, link);
  }
  
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventLinks);
const CustomStyled = withCustomStyles("generic/event-links")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;