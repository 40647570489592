import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {

  /**
   * Translations for client related words
   */
  client: {
    name: string;
    pageTitle: string;
    toClientName: string;
    siteLogoAltText: string;
    footerLogoAtlText: string;
    copyrightText: string;
    addressName: string;
  };

  /**
   * Translations related to authorization
   */
  auth: {
    login: string;
    logout: string;
    register: string;
  };

  /**
   * Translations for generic dialog
   */
  genericDialog: {
    ok: string;
    cancel: string;
  };

  /**
   * Translations for generic phrases
   */
  generic: {
    dateStartFrom: string;
    dateTimeRange: string;
    imageAltText: string;
  };

  /**
   * Translations for timepicker related words
   */
  timepicker: {
    today: string;
    cancel: string;
    clear: string;
  };

  /**
   * Translations for validator
   */
  validator: {
    invalidUrl: string;
    requiredField: string;
    validatorHasFailed: string;
    invalidFieldsWereFound: string;
  };

  /**
   * Translations for countries
   */
  countries: {
    FI: string;
  };

  /**
   * Translations for event related words
   */
  event: {
    image: string;
    price: string;
    contact: string;
    address: string;
    location: string;
    locationDescription: string;
    addEvent: string;
    editEvent: string;
    allEvents: string;
    save: string;
    organizer: string;
    buyTickets: string;
    attachments: string;
    contactName: string;
    addNewEvent: string;
    contactEmail: string;
    contactPhone: string;
    eventPeriods: string;
    registration: string;
    organizerName: string;
    freeOfCharge: string;
    organizerEmail: string;
    organizerPhone: string;
    longTermEvents: string;
    loadMoreEvents: string;
    extraInformation: string;
    loadMoreLongTermEvents: string;
    eventCategories: string;
    audiences: string;
    externalLinks: string;
    
    filters: {
      freeSearch: string;
      freeSearchExample: string;
      startDate: string;
      endDate: string;
      where: string;
      whom: string;
      what: string;
    };

    eventAddedSuccess: string;
    eventEditSuccess: string;
    eventDeleteSuccess: string;
  };

  /**
   * Translations for place related words
   */
  place: {
    placeAddedSuccess: string;
    placeEditSuccess: string;
    placeDeleteSuccess: string;
  };

  /**
   * Translations for keyword related words
   */
  keyword: {
    keywordAddedSuccess: string;
    keywordEditSuccess: string;
    keywordDeleteSuccess: string;
  };

  /**
   * Translations for event form related words
   */
  eventForm: {
    addNewPlaceButtonText: string;
    cropTitle: string;
    cropInstructions: string;
    crop: string;
    addNewTime: string;
    confirmUpdateEvent: string;
    confirmAddEvent: string;
    eventAddFailed: string;
    eventAddedSuccessfully: string;
    eventUpdatedSuccesfully: string;
    add: string;
    addNewPlace: string;
    chooseImage: string;
    removeImage: string;
    newPlaceAdded: string;
    pickExistingImage: string;
    chooseExistingPlace: string;
    eventImageDescription: string;
    eventNameHeading: string;
    chooseAttachments: string;
    onlineTicketSales: string;
    onlineTicketSalesLink: {
      fi: string;
      sv: string;
      en: string;
    };
    eventDescriptionHeading: string;
    eventShortDescriptionHeading: string;
    eventPriceDescriptionHeading: string;
    eventPriceDescriptionHeadingDescription: string;
    previewAlt: string;
    loadingImage: string;
    newPlaceName: {
      fi: string;
      sv: string;
      en: string;
    };
    languages: {
      fi: string;
      sv: string;
      en: string;
    };
    eventName: {
      fi: string;
      sv: string;
      en: string;
    };
    eventDescription: {
      fi: string;
      sv: string;
      en: string;
    };
    eventShortDescription: {
      fi: string;
      sv: string;
      en: string;
    };
    eventPriceDescription: {
      fi: string;
      sv: string;
      en: string;
    };
    eventRegistrationDescription: {
      fi: string;
      sv: string;
      en: string;
    };
    placeDescription: {
      fi: string;
      sv: string;
      en: string;
    };
    eventLinks: {
      youtube:string;
      twitter: string;
      facebook: string;
      instagram: string;
      instagramHashTag: string;
      youtubeOrVimeoLink: string;
      eventOrganizerLink: string;
    };
    
    location: string;
    eventImage: string;
    eventTiming: string;
    eventTimingDescription: string;
    eventBegins: string;
    eventEnds: string;
    freeOfCharge: string;
    eventLocation: string;
    eventLocationDescription: string;
    eventCategories: string;
    otherAttachments: string;
    eventRegistration: string;
    eventRegistrationDescriptionInfo: string;
    signUpDescription: string;
    eventLinksHeading: string;
    eventLinksHeadingDescription: string;
    responsiblePerson: string;
    eventOrganization: string;
    cityOrMunicipality: string;
    chooseLocalizations: string;
    linkToEventSignUpPage: string;
    eventOrganizationName: {
      fi: string;
      sv: string;
      en: string;
    };
    responsiblePersonName: string;
    responsiblePersonPhone: string;
    responsiblePersonEmail: string;
    eventOrganizationPhone: string;
    eventOrganizationEmail: string;
    eventContactInformation: string;
    additionalLocationInformation: string;
    keywordDescription: string;
    keywordNoneDescription: string;
    descriptionText: string;
    eventProviderDescription: string;
    responsiblePersonDescription: string;
  };

  /**
   * Translations for home screen related words
   */
  homeScreen: {
    heading: string;
    showEventsOnMapHelp: string;
    showEventsOnListHelp: string;
    showEventsOnList: string;
    showEventsOnMap: string;
  };

  /**
   * Translations for cookie consent
   */
  cookieConsent: {
    mainText: string;
    approve: string;
    decline: string;
  }

  /**
   * Translations for info screen related words
   */
  infoScreen: {
    accessibility: string;
    privacyPolicy: string;
    returnToFrontpage: string;
    publisherHelp: string;
  };

  /**
   * Translations for footer texts
   */
  footer: {
    feedbackLinkButtonText: string;
    privacyPolicy: string;
    accessibilityDescription: string;
    contactInformation: string;
    publisherHelp: string;
    login: string;
    feedbackLink: string;
  };

  /**
   * Translations for app errors
   */
  errors: {
    idNotFound: string;
    priceMissing: string;
    cropIsTooSmall: string;
    imageIsTooSmall: string;
    locationMissing: string;
    eventNameMissing: string;
    shortDescriptionNotFound: string;
    descriptionNotFound: string;
    eventDurationMissing: string;
    eventProviderMissing: string;
    failMessage: string;
    permissionDenied: string;

    event: {
      failedToLoadEvent: string;
      noEditPermissionMessage: string;
      eventAddedSuccessfullyMessage: string;
      eventEditedSuccessfullyMessage: string;
    };
  };

  /**
   * Translations for admin view
   */
  admin: {
    createdTime: string;
    dialogDeleteEventTitle: string;
    dialogDeleteEventDescription: string;
    dialogDeleteKeywordTitle: string;
    dialogDeleteKeywordDescription: string;
    dialogDeletePlaceTitle: string;
    dialogDeletePlaceDescription: string;
    logOut: string;
    delete: string;
    copy: string;
    edit: string;
    save: string;
    new: string;
    search: string;
    events: string;
    keywords: string;
    newKeyword: string;
    updateKeyword: string;
    newEvent: string;
    updateEvent: string;
    eventTime: string;
    finnishKeywordLabel: string;
    englishKeywordLabel: string;
    swedishKeywordLabel: string;
    keywordSets: string;
    update: string;
    cancel: string;
    places: string;
    newPlace: string;
    updatePlace: string;
    placeNameFin: string;
    placeNameEng: string;
    placeNameSwe: string;
    coordinatesLat: string;
    coordinatesLon: string;
    addressLocalityFin: string;
    addressLocalityEng: string;
    addressLocalitySwe: string;
    infoUrlFin: string;
    infoUrlEng: string;
    infoUrlSwe: string;
    streetAddressFin: string;
    streetAddressEng: string;
    streetAddressSwe: string;
    addressCountry: string;
    postalCode: string;
    listed: string;
    notListed: string;
    unlist: string;
    list: string;
    userManagement: string;

    placeName: {
      fi: string;
      sv: string;
      en: string;
    };

    placeDescription: {
      fi: string;
      sv: string;
      en: string;
    };

    addressLocality: {
      fi: string;
      sv: string;
      en: string;
    };

    infoUrl: {
      fi: string;
      sv: string;
      en: string;
    };
    
    streetAddress: {
      fi: string;
      sv: string;
      en: string;
    };
  };
  profile: {
    pageTitle: string;
    eventsTitle: string;
    delete: string;
    edit: string;
    copy: string;
    yes: string;
    dialogDeleteEventTitle: string;
    dialogDeleteEventDescription: string;
    deleteSuccessfulMessage: string;
    deleteFailedMessage: string;
    copySuccessfulMessage: string;
    copyFailedMessage: string;
  },

}

const strings: IStrings = new LocalizedStrings({
  en: require("./en.json"),
  fi: require("./fi.json")
});

export default strings;
