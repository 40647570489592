import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import Api from "../../api/api";
import { v4 as uuidv4 } from 'uuid';
import deepEqual from "fast-deep-equal";
import LocalizedInput from "./localized-input";
import strings from "../../localization/strings";
import styles from "../../styles/generic/form-item";
import { SnackbarMessage } from "../layouts/app-layout";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SimpleReactValidator from "simple-react-validator";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { Place, PlacePositionTypeEnum } from "../../generated/client";
import CoordinatePicker from "../admin/admin-places/coordinate-picker";
import { CustomStyles, Locale, Localizations, LocalizedValues, NullableToken } from "../../types";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, withStyles, WithStyles } from '@material-ui/core';
import { Config } from "../../constants/configuration"

const config = Config.getConfig();

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  places: Place[];
  division: string;
  locationId?: string;
  showMessages: boolean;
  eventLocation?: Place;
  placesLoaded?: boolean;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  localizations: Localizations;
  validator: SimpleReactValidator;
  placeDescription: LocalizedValues;
  onPlaceUpdate: (place: Place) => void;
  onDivisionUpdate?: (value: string) => void;
  setSnackbarMessage?: (message: SnackbarMessage) => void;
  onPlaceDescriptionUpdate: (locale: string, value: string) => void;
}

/**
 * Interface describing component state
 */
interface State {
  newPlace: Place;
  tabIndex: number;
  disabled: boolean;
  address_localities: string[];
}

/**
 * Event location component
 */
class EventLocation extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      tabIndex: 0,
      disabled: false,
      newPlace: {
        name: {
          fi: "",
          sv: "",
          en: ""
        },
        position: {
          coordinates: [
            62.7880085,
            22.8492787
          ],
          type: PlacePositionTypeEnum.Point
        },
        customData: {
          listed: "false",
        },
        streetAddress: {
          fi: "",
          sv: "",
          en: ""
        },
        addressLocality: {
          fi: "",
          sv: "",
          en: ""
        },
        dataSource: config.dataSource,
      },
      address_localities: [
        "Alajärvi",
        "Alavus",
        "Evijärvi",
        "Ilmajoki",
        "Isojoki",
        "Isokyrö",
        "Karijoki",
        "Kauhajoki",
        "Kauhava",
        "Kuortane",
        "Kurikka",
        "Lappajärvi",
        "Lapua",
        "Seinäjoki",
        "Soini",
        "Teuva",
        "Vimpeli",
        "Ähtäri"
      ]
    };
  }

  /**
   * Component did update
   *
   * @param prevProps previous component properties
   * @param prevState previous component state
   */
  public componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!deepEqual(prevState.newPlace, this.state.newPlace)) {
      this.setState({
        disabled: false
      });
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { customStyles, classes } = this.props;
    const { newPlace, tabIndex } = this.state;

    if (!newPlace.addressLocality) {
      return null;
    }

    return (
      <Box mt={ 2 } mb={ 2 }>
        <Tabs
          onChange={ this.setTabIndex }
          value={ tabIndex }
        >
          <Tab value={ 0 } label={ strings.eventForm.chooseExistingPlace } />
          <Tab value={ 1 } label={ strings.eventForm.addNewPlace } />
        </Tabs>
        <Box 
          className={ classes.content }
          style={ customStyles?.content }
        >
          { this.renderExistingPlaceForm(tabIndex === 0) }
          { this.renderNewPlaceForm(tabIndex === 1) }
        </Box>
      </Box>
    );
  }

  /**
   * Add existing place render method
   *
   * @param visible boolean
   */
  private renderExistingPlaceForm = (visible: boolean) => {
    const {
      places,
      classes,
      division,
      validator,
      customStyles,
      placesLoaded,
      eventLocation,
      localizations,
      placeDescription,
      onPlaceDescriptionUpdate
    } = this.props;

    const { address_localities } = this.state;

    return (
      <Box display={ visible ? "block" : "none" } mt={ 2 } >
        <FormControl fullWidth 
          className={ classes.spacing }
          style={ customStyles?.spacing }
        >
          <InputLabel id="label">{ strings.eventForm.cityOrMunicipality }</InputLabel>
          <Select
            fullWidth
            labelId="label"
            value={ division }
            onChange={ this.updateDivision }
          >
            {
              address_localities.map((address_locality, index) => 
                <MenuItem key={ index } value={ address_locality }>
                  { address_locality }
                </MenuItem>
              )
            }
          </Select>
          { this.renderValidatorMessage("division", division) }
        </FormControl>
        <div 
          className={ classes.spacing }
          style={ customStyles?.spacing }
        >
          { placesLoaded &&
            <Autocomplete
              id="event-location"
              value={ eventLocation ? eventLocation : null }
              getOptionSelected={ (option, value) => option.id === value.id }
              getOptionLabel={ (place) => place.name.fi ? place.name.fi : "Menuitem" }
              options={ places.filter(place => place.addressLocality?.fi === division) }
              renderInput={ (params) =>
                <TextField
                  {...params}
                  label={ strings.eventForm.location }
                />
              }
              onChange={ this.updatePlace }
            />
          }
          { this.renderValidatorMessage("location", eventLocation?.id) }
        </div>
        <div 
          className={ classes.spacing }
          style={ customStyles?.spacing }
        >
          <LocalizedInput
            validator={ validator }
            localizations={ localizations }
            onChange={ onPlaceDescriptionUpdate }
            localizedStrings={ strings.eventForm.placeDescription }
            localizedValues={ (placeDescription || { fi: "", sv: "", en: "" }) as LocalizedValues }
          />
        </div>
      </Box>
    );
  }

  /**
   * Add new place render method
   *
   * @param visible boolean
   */
  private renderNewPlaceForm = (visible: boolean) => {
    const {
      classes,
      validator,
      customStyles,
      localizations,
      eventLocation,
      placeDescription,
      onPlaceDescriptionUpdate
    } = this.props;
    const { newPlace, disabled } = this.state;

    if (!newPlace.addressLocality) {
      return null;
    }

    return (
      <Box display={ visible ? "block" : "none" } mt={ 2 }>
        <Box mb={ 3 }>
          <InputLabel id="label">{ strings.eventForm.cityOrMunicipality }</InputLabel>
          <CoordinatePicker
            place={ newPlace }
            onUpdateLocation={ this.setNewPlaceLocation }
          />
        </Box>
        <Box mb={ 3 }>
          { localizations.fi &&
            <TextField
              fullWidth
              disabled={ disabled }
              value={ newPlace.name.fi }
              onChange={ this.setNewPlaceName("fi") }
              label={ strings.eventForm.newPlaceName.fi }
            />
          }
          { localizations.en &&
            <TextField
              fullWidth
              disabled={ disabled }
              value={ newPlace.name.en }
              onChange={ this.setNewPlaceName("en") }
              label={ strings.eventForm.newPlaceName.en }
            />
          }
        </Box>
        <div 
          className={ classes.spacing }
          style={ customStyles?.spacing }
        >
          <LocalizedInput
            disabled={ disabled }
            validator={ validator }
            localizations={ localizations }
            onChange={ onPlaceDescriptionUpdate }
            localizedStrings={ strings.eventForm.placeDescription }
            localizedValues={ (placeDescription || { fi: "", sv: "", en: "" }) as LocalizedValues }
          />
        </div>
        { this.renderValidatorMessage("new_location", eventLocation?.id) }
        <Button disabled={ disabled } onClick={ this.addNewPlace }>
          { disabled ?
            strings.eventForm.newPlaceAdded :
            strings.eventForm.addNewPlaceButtonText
          }
        </Button>
      </Box>
    );
  }

  /**
   * Method for setting autocomplete options
   *
   * @param updatedPlace linked events place object
   */
  private setNewPlaceLocation = (updatedPlace: Place) => {
    this.setState({ newPlace: updatedPlace });
  }

  /**
   * Sets tab index
   *
   * @param event event object
   * @param newValue new tab index value
   */
  private setTabIndex = (event: React.ChangeEvent<{ }>, newValue: number) => {
    this.setState({ tabIndex: newValue });
  }


  /**
   * Method for rendering validator message
   *
   * @param field field
   * @param value value
   */
  private renderValidatorMessage = (field: string, value?: string) => {
    const { validator } = this.props;

    return validator.message(field, value, "required");
  }

  /**
   * Method for updating division
   *
   * @param value string
   */
  private updateDivision = (event: React.ChangeEvent<any>) => {
    const { onDivisionUpdate } = this.props;
    const value = event.target.value;

    if (!onDivisionUpdate) {
      return;
    }

    onDivisionUpdate(value);
  }

  /**
   * Event handler for localized field change
   * 
   * @param property property
   * @param locale locale
   * @param value value
   */
  private setNewPlaceName = (locale: Locale) => (event: React.ChangeEvent<any>) => {
    const { newPlace } = this.state;
    const updatedPlace: Place = { ...newPlace };
    updatedPlace["name"] = updatedPlace["name"] || {};
    updatedPlace["name"][locale] = event.target.value;
    this.setState({
      newPlace: updatedPlace
    });
  }

  /**
   * Method for updating event's place
   * 
   * @param event React change event
   * @param value new place
   */
  private updatePlace = (event: React.ChangeEvent<any>, value: Place | null) => {
    const { onPlaceUpdate } = this.props;

    if (!value) {
      return;
    }

    onPlaceUpdate(value);
  }

  /**
   * Method for adding new place
   */
  private addNewPlace = async () => {
    const { accessToken, onPlaceUpdate, onDivisionUpdate, setSnackbarMessage } = this.props;
    const { newPlace } = this.state;

    const originId = this.generateOriginId();
    newPlace.originId = originId;

    try {
      const filtersApi = Api.getFilterApi(accessToken);
      const place = await filtersApi.placeCreate({
        placeObject: newPlace
      });

      if (!place.addressLocality || !place.addressLocality.fi || !onDivisionUpdate) {
        return;
      }

      onPlaceUpdate(place);
      onDivisionUpdate(place.addressLocality.fi);

      if (!setSnackbarMessage) {
        return;
      }

      this.setState({
        disabled: true
      });
      setSnackbarMessage({
        severity: "success",
        message: strings.place.placeAddedSuccess
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Method for generating origin id
   */
  private generateOriginId = (): string => {
    const { newPlace } = this.state;
    return `${ newPlace.dataSource }:${ uuidv4() }`;
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventLocation);
const CustomStyled = withCustomStyles("generic/event-location")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;