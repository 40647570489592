import * as React from "react";

import { Box, IconButton, withStyles, WithStyles } from "@material-ui/core";
import { Event } from "../../generated/client";

import { withCustomStyles } from "../hocs/with-custom-styles";
import styles from "../../styles/generic/contact-info";
import { CustomStyles } from "../../types";

import FacebookIcon from "@material-ui/icons/Facebook";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import InstagramIcon from "@material-ui/icons/Instagram";
import PublicIcon from "@material-ui/icons/Public";
import GroupIcon from "@material-ui/icons/Group";
import TwitterIcon from "@material-ui/icons/Twitter";
import YoutubeIcon from "@material-ui/icons/YouTube";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';

/**
 * Interface describing optional props
 */
export interface OptionalProps { }

/**
 * Interface describing component properties
 */
export interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  event: Event;
}

/**
 * Functional component for external links
 * 
 * @param props component props
 */
const ExternalLinks: React.FC<Props & OptionalProps> = ({ event }) => {

  /**
   * Render external link buttons
   * 
   * @param event 
   * 
   * @returns element of external links or
   *          element of infoUrl if external links are empty or
   *          null if external links and infoUrl are empty
   */
  const renderExternalLinks = () => {
    let { externalLinks, infoUrl } = event;
    
    if (externalLinks === undefined) {
      externalLinks = [];
    }

    if (!externalLinks.find(item => item.name === "infoUrl") && infoUrl && infoUrl.fi) {
      externalLinks.unshift({
        name: "infoUrl",
        link: infoUrl.fi
      });
    }

    let renderButtons = (
      <>
        {
          externalLinks.map((link, index) => {
            switch(link.name){
              case "infoUrl":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <PublicIcon fontSize="large" htmlColor="#000" />
                </IconButton>
                );
              case "YouTube":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <YoutubeIcon fontSize="large" htmlColor="#000" />
                </IconButton>
                );
              case "Twitter":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <TwitterIcon fontSize="large" htmlColor="#000" />
                </IconButton>
                );
              case "Facebook":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <FacebookIcon fontSize="large" htmlColor="#000" />
                </IconButton>
                        );
              case "Instagram":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <InstagramIcon fontSize="large" htmlColor="#000" />
                </IconButton>
                );
              case "YouTube Or Vimeo Link":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <OndemandVideoIcon fontSize="large" htmlColor="#000" />
                </IconButton>              );
              case "Event Organizer Link":
                return (
                <IconButton key={ index } href={ link.link ? link.link : "" } target="_blank">
                  <GroupIcon fontSize="large" htmlColor="#000" />
                </IconButton>
                );
              default:
                return null;
            }
          })
        }
      </>
    );

    return renderButtons;
  }

  /**
   * Component render
   */
  return (
    <Box mt={ 2 } display="flex">
      { renderExternalLinks() }
    </Box>
  );
}

const Styled = withStyles(styles)(ExternalLinks);
const CustomStyled = withCustomStyles("generic/event-contact-information")(Styled);

export default CustomStyled;
