import * as React from "react";

import { ReactComponent as CalendarIcon } from "../../resources/svg/kalenteri-icon.svg";
import { withStyles, WithStyles, Typography, List, ListItem, Grid } from "@material-ui/core";
import { CustomStyles, NullableToken, Attachment } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ReduxActions, ReduxState } from "../../store";
import styles from "../../styles/event/event-content";
import EventUtils from "../../utils/event-utils";
import strings from "../../localization/strings";
import { Event, Keyword, KeywordName } from "../../generated/client";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import theme from "../../dynamic-content/theme";
import { Helmet } from "react-helmet";
import { Config } from "../../constants/configuration";

const config = Config.getConfig();

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  event: Event;
  subEvents?: Event[];
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  keywords: string[];
}

/**
 * Component for event content
 * 
 * @param props component props
 */
class EventContent extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      keywords: []
    };
  }

  /**
   * Component did mount life-cycle handler
   */
  componentDidMount = async () => {
    await this.getKeywordNames();
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, event } = this.props;
    const { keywords } = this.state;

    const prices = EventUtils.getEventPrices(event);
    const duration = EventUtils.getEventDuration(event);
    const description = EventUtils.getEventDescription(event);
    const registrationDescription = this.getRegistrationDescription();

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <div
          className={ classes.info }
          style={ customStyles?.info }
        >
          <div
            className={ classes.infoItem }
            style={ customStyles?.ininfoItemfo }
          >
            <CalendarIcon
              color="primary"
              style={{ marginRight: theme.spacing(2) }}
            />
            <Typography variant="h6">
              { duration }
            </Typography>
          </div>
          { keywords.length !== 0 &&
            <div
              className={ classes.infoItem }
              style={ customStyles?.infoItem }
            >
              <VisibilityIcon
                color="primary"
                style={{ marginRight: theme.spacing(2) }}
                />
              <Typography variant="h6">
                { this.mapKeywords() }
              </Typography>
            </div>
          }
        </div>
        <div
          className={ classes.content }
          style={ customStyles?.content }
        >
          { this.renderShortDescription() }
          { description }
        </div>
        <div
          className={ classes.price }
          style={ customStyles?.price }
        >
        <Grid container spacing={ 0 }>
          <Grid item xs={ 12 } sm={ 6 }>
            <Typography variant="h3">
              { strings.event.price }
            </Typography>
            { prices }
          </Grid>        
          <Grid item xs={ 12 } sm={ 6 }>
            { registrationDescription &&
            <>
              <Typography variant="h3">
                { strings.event.registration }
              </Typography>
              <p>
                { registrationDescription.fi }
              </p>
            </>
            }
          </Grid>
        </Grid>
          { this.renderEventAttachments() }
        </div>
        <div 
          className={ classes.content }
          style={ customStyles?.content }
        >
          { this.renderEventPeriods() }
        </div>
      </div>
    );
  }

  /**
   * Method for fetching registration data
   */
  private getRegistrationDescription = () => {
    const { event } = this.props;
    const registration = EventUtils.getEventCustomData(event);

    const registrationDescription = registration.registration_description ? JSON.parse(registration.registration_description) : undefined;
    
    if (!registrationDescription || registrationDescription.fi === "") {
      return undefined;
    }

    return registrationDescription;
  }

  /**
   * Method for rendering event attachments
   */
  private renderEventAttachments = () => {
    const { event } = this.props;
    const attachments: Attachment[] = EventUtils.getEventAttachments(event);

    if (attachments.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <Typography variant="h4">
          { strings.event.attachments }
        </Typography>
        <List>
          {
            attachments.map((attachment, index) => (
              <ListItem key={ index }>
                <a href={ attachment.url }>
                  { attachment.name }
                </a>
              </ListItem>
            ))
          }
        </List>
      </React.Fragment>
    );
  }

  /**
   * Method for rendering event periods
   */
  private renderEventPeriods = () => {
    const { customStyles, subEvents, classes } = this.props;

    if (!subEvents || !subEvents.length) {
      return null;
    }

    return (
      <div>
        <Typography 
          variant="h3" 
          className={ classes.eventPeriods }
          style={ customStyles?.eventPeriods }
        >
          { strings.event.eventPeriods }
        </Typography>
        {
          subEvents.map((subEvent, index) => {
            const duration = EventUtils.getEventDuration(subEvent);

            return (
              <div 
                key={ index } 
                className={ classes.period }
                style={ customStyles?.period }
              >
                <Typography 
                  variant="subtitle1" 
                  className={ classes.subEventTitle }
                  style={ customStyles?.subEventTitle }
                >
                  { duration }
                </Typography>
              </div>
            );
          })
        }
      </div>
    );
  }

  /**
   * Method for getting keyword names
   */
  private getKeywordNames = async () => {
    const { accessToken, event } = this.props;
    console.log("in keywords")

    try {
      const eventKeywords = await EventUtils.getKeywords(event, accessToken);
      const keywordSets = await EventUtils.getKeywordSets(accessToken);
      const categoriesSet = keywordSets.find(keywordSet => keywordSet.id === config.categoryId);
      console.log(categoriesSet)
      const categories: Keyword[] =  categoriesSet?.keywords || [];
      console.log(categories)

      const keywords = eventKeywords.filter(item =>
        categories.some(category => category.id === item.id)
      );
      const keywordStrings = keywords.map(keyword =>
        keyword.name[strings.getLanguage() as keyof KeywordName] || ""
      );
      
      this.setState({ keywords: keywordStrings });
    } catch(e) {
      console.error(e, "failed to load keywords");
    }
  }

  /**
   * Method for mapping keywords
   */
  private mapKeywords = () => {
    const { keywords } = this.state;

    return keywords.join(", ");
  }

  /**
   * Method for rendering short description
   */
  private renderShortDescription = () => {
    const { event } = this.props;
    const shortDescription = EventUtils.getEventShortDescription(event);

    if (!shortDescription) {
      return null
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta name="description" content={ shortDescription.toString() }/>
          <meta name="og:description" content={ shortDescription.toString() }/>
        </Helmet>
        <Typography>
          { shortDescription }
        </Typography>
      </React.Fragment>
    );
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventContent);
const CustomStyled = withCustomStyles("event/event-content")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
