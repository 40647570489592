import * as React from 'react';
import styles from "../../styles/generic/localized-input";
import { withCustomStyles } from '../hocs/with-custom-styles';
import { TextField, withStyles, WithStyles } from '@material-ui/core';
import { CustomStyles, Localizations, LocalizedStrings, LocalizedValues } from '../../types';
import SimpleReactValidator from 'simple-react-validator';
import EventUtils from '../../utils/event-utils';

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  required?: boolean;
  customStyles?: CustomStyles;
  localizations: Localizations;
  validator?: SimpleReactValidator;
  localizedValues: LocalizedValues;
  localizedStrings: LocalizedStrings;
  onChange: (locale: string, value: string) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Localized textfield component
 */
class LocalizedTextField extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderLocalizedTextFields() }
      </div>
    );
  }

  /**
   * Method for rendering localized inputs
   */
  private renderLocalizedTextFields = () => {
    const { localizations, localizedStrings, localizedValues } = this.props;
    const localizationKeys = EventUtils.getLocalizationKeys();

    return localizationKeys.map((key: string, index: number) => {

      if (!localizations[key as keyof Localizations]) {
        return null;
      }

      const localizedString: string = localizedStrings[key as keyof object];
      const localizedValue: string = localizedValues[key as keyof object];

      return (
        <React.Fragment key={ index }>
          <TextField 
            value={ localizedValue }
            label={ localizedString }
            onChange={ this.onChangeLocalization(key) }
          />
          { this.renderValidatorMessage(`${ localizedString.split(" ").join("_") }_${ key }`, localizedValue) }
        </React.Fragment>
      );
    });
  }

  /**
   * Method for rendering validator message
   *
   * @param field field
   * @param value string
   */
  private renderValidatorMessage = (field: string, value: string) => {
    const { validator, required } = this.props;

    if (!validator) {
      return null;
    }

    return validator.message(field, !!value || !required, "required|accepted");
  }

  /**
   * Method for changing localized value
   *
   * @param key key
   * @param event event object
   */
  private onChangeLocalization = (key: string) => (event: React.ChangeEvent<any>) => {
    const value: string = event.target.value;
    this.props.onChange(key, value);
  }

}

const Styled = withStyles(styles)(LocalizedTextField);
const CustomStyled = withCustomStyles("generic/localized-textfield")(Styled);

export default CustomStyled;
