import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

import dividerPattern  from  "../../resources/svg/ep-divider.svg";

const maxWidth = 900;

export default createStyles({

  container: {
    width: "100%",
    marginTop: theme.spacing(8)
  },

  divider: {
    height: 150,
    backgroundImage: `url(${ dividerPattern })`,
    marginBottom: theme.spacing(16)
  },

  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      maxWidth: maxWidth,
      width: "80vw",
      paddingLeft: 0,
      paddingRight: 0,
      margin: "0 auto"
    }
  },

  innerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },

  contactContainer: {
    display: "flex",
    flexDirection: "column"
  },

  logo: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400,
    },
    "& img": {
      width: "100%",
      maxWidth: "100%"
    }
  },

  socialContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignSelf: "center",
    }
  },

  mailingListButton: {
    "& .MuiButton-label": {
      fontWeight: 500,
      textTransform: "uppercase"
    }
  },

  additionalInfo: {
    background: "#dcdcda",
    width: "100%",
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      textAlign: "center"
    }
  },

  additionalInfoContent: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(2),
    "& a": {
      "&:nth-child(2)": {
        borderLeft: 0
      },
      [theme.breakpoints.down("sm")]: {
        borderLeft: 0
      },
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
      flexDirection: "row",
      maxWidth: maxWidth,
      width: "80vw",
      margin: "0 auto",
    }
  },

  copyright: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    }
  },

  link: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderLeft: `1px solid ${ theme.palette.secondary.light } `,
    transition: "color 0.2s ease-out",
    "&:hover": {
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    }
  }

});