import { cleanEnv, bool, str, url, num } from "envalid";
import { StaticConfig } from "../types";

/**
 * Validates that environment variables are in place and have correct form
 */
const env = cleanEnv(process.env, {
  REACT_APP_KEYCLOAK_CLIENT_ID:str(),
  REACT_APP_KEYCLOAK_REALM:str(),
  REACT_APP_KEYCLOAK_URL:url(),
  REACT_APP_API_BASE_PATH:url(),
  REACT_APP_DATASOURCE:str(),
  REACT_APP_EVENT_PUBLISHER:str(),
  REACT_APP_GOOGLE_MAP_API_KEY:str(),
  REACT_APP_SENTRY_DSN:url(),
  REACT_APP_CATEGORY_ID:str(),
  REACT_APP_AUDIENCE_ID:str(),
});

/**
 * Class for software configuration
 */
export class Config {

  /**
   * Get static application configuration
   *
   * @returns static application configuration
   */
  public static getConfig = (): StaticConfig => {
    return {
      keycloakConfig: {
        url: env.REACT_APP_KEYCLOAK_URL,
        realm: env.REACT_APP_KEYCLOAK_REALM,
        clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID
      },
      apiBasePath: env.REACT_APP_API_BASE_PATH,
      sentryDSN: env.REACT_APP_SENTRY_DSN,
      googleMapAPIKey: env.REACT_APP_GOOGLE_MAP_API_KEY,
      dataSource: env.REACT_APP_DATASOURCE,
      eventPublisher: env.REACT_APP_EVENT_PUBLISHER,
      categoryId: env.REACT_APP_CATEGORY_ID,
      audienceId: env.REACT_APP_AUDIENCE_ID,
    };
  }
}