import { CSSProperties } from "react";
import { Place } from "../generated/client";

/**
 * Interface describing an access token
 */
export interface AccessToken {
  created: Date;
  access_token: string;
  expires_in?: number;
  refresh_token?: string;
  refresh_expires_in?: number;
  firstName?: string;
  lastName?: string;
  userId?: string;
  email?: string;
  realmRoles: string[];
  clientRoles: string[];
}

/**
 * Union type for nullable access token
 */
export type NullableToken = AccessToken | null;

/**
 * Interface describing custom CSS styles
 */
export interface CustomStyles {
  [className: string]: CSSProperties;
}

/**
 * Interface describing localization options
 */
export interface Localizations {
  fi: boolean;
  sv: boolean;
  en: boolean;
}

/**
 * Interface describing localized values
 */
export interface LocalizedValues {
  fi: string;
  sv: string;
  en: string;
}

/**
 * Interface describing localized strings
 */
export interface LocalizedStrings {
  fi: string;
  sv: string;
  en: string;
}

/**
 * Interface describing keywords table row data
 */
export interface IKeywordRowData {
  id: number;
  finnish: string;
  english: string;
  swedish: string;
};

/**
 * Interface describing keywords table column data
 */
export interface IKeywordColumnData {
  title: string;
  field: string;
}

/**
 * Interface describing events row data
 */
export interface IEventsRowData {
  name: string;
  date: string;
  publicity: string;
};

/**
 * Interface describing events column data
 */
export interface IEventsColumnData {
  title: string;
  field: string;
  customSort?: (a: any, b: any) => number; 
}

/**
 * interface describing search filter parameters
 */
export interface FiltersParam {
  text?: string;
  selectedAudienceIds: string[];
  selectedCategoryIds: string[];
  selectedPlaceIds: string[];
  dateStart: Date;
  dateEnd?: Date;
  page: number;
  pageSize: number;
}

 /**
 * Interface for setting events by place id
 */
export interface EventsByPlace {
  placeId: string;
  place: Place;
  events: Event[];
}

/**
 * Interface for hard coded place filter data
 */
export interface PlaceByLocality {
  id: string;
  address_locality_fi: string;
}

/** 
 * Interface describing events table data
 */
export interface EventData {
  id: string,
  nameFi: string,
  nameEn: string,
  nameSv: string,
  realId: string
};

/**
 * Interface describing event periods
 */
export interface EventPeriod {
  startTime: Date;
  endTime: Date;
}

/**
 * Interface describing presigned post data
 */
export interface PresignedPostData {
  error: any
  message: any
  data: PostData;
  basePath: string;
}

/**
 * Interface describing post data
 */
export interface PostData {
  url: string;
  fields: DataFields;
}

/**
 * Interface describing datafields
 */
export interface DataFields {
  key: string;
  bucket: string;
  Policy: string;
  "X-Amz-Date": string;
  "Content-Type": string;
  "X-Amz-Algorithm": string;
  "X-Amz-Credential": string;
  "X-Amz-Security-Token": string;
  "X-Amz-Signature": string;
  [Key: string]: string;
}

/**
 * Interface describing attachment
 */
export interface Attachment {
  url: string;
  name: string;
}

/**
 * Interface describing places table column data
 */
export interface IPlaceColumnData {
  title: string;
  field: string;
}

export interface CustomData {
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  provider_phone: string;
  provider_email: string;
  other_attachments: string;
  registration_description: string;
}

/**
 * Localized value
 */
export interface LocalizedValue {
  fi?: string,
  en?: string,
  sv?: string
}

/**
 * Enum for linked events object types
 */
export enum LinkedEventsObject {
  Event = "event",
  Place = "place",
  Keyword = "keyword"
}

/**
 * Locale key
 */
export type Locale = "fi" | "en";

/**
 * Available locales list
 */
export const LOCALES: Locale[] = ["fi", "en"];

export type OpenPage = "googlemaps" | "eventlist";

/**
 * Interface describing static application configuration
 */
 export interface StaticConfig {
  keycloakConfig: KeycloakConfig;
  apiBasePath: string;
  sentryDSN: string;
  googleMapAPIKey: string;
  dataSource: string;
  eventPublisher: string;
  categoryId: string;
  audienceId: string;
}

/**
 * Interface describing keycloak configuration
 */
 export interface KeycloakConfig {
  url: string;
  realm: string;
  clientId: string;
}
