import * as React from "react";

import { History } from "history";
import GoogleMapReact, { Coords, MapOptions } from "google-map-react";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { Box, CircularProgress, withStyles, WithStyles } from "@material-ui/core";
import { NullableToken, CustomStyles, FiltersParam } from "../../types";
import { ReduxActions, ReduxState } from "../../store";
import styles from "../../styles/generic/all-events-map";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EventListRequest, Place } from "../../generated/client";
import GoogleMapMarker from "./google-map-marker"
import { Event } from "../../generated/client";
import Api from "../../api/api";
import  PopperComponent  from "./popper-component";
import _isEqual from "lodash/isEqual";
import { Config } from "../../constants/configuration";

const config = Config.getConfig();

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  filters: FiltersParam;
  history: History<History.LocationState>;
}

/**
 * Component state
 */
interface State { 
  eventsByPlacesList: EventsByPlace[];
  loading: boolean;
  markerAnchorEl: null | undefined | HTMLElement;
  eventsInPopper: Event[];
  center: Coords | undefined;
  onChangeCoords?: Coords;
}

/**
 * Interface for setting events by place id
 */
interface EventsByPlace {
  placeId: string;
  place: Place;
  events: Event[];
}

/**
 * All events maps component 
 */
class AllEventsMap extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      eventsByPlacesList: [],
      loading: false,
      markerAnchorEl: null,
      eventsInPopper: [],
      center: {
        lat: 62.799252,
        lng: 22.850832
      }
    }
  }

  /**
   * Component did mount life-cycle handler
   */
  componentDidMount = async () => {
    this.setState({
      loading: true
    });

    await this.loadEvents();

    this.setState({
      loading: false
    });
  }

  /**
   * Component did update. Loads events again if anything in the filtters has changes
   *
   * @param prevProps
   * @param prevState
   */
  public componentDidUpdate = async (prevProps: Props, prevState: State) => {
    if (!_isEqual(prevProps.filters, this.props.filters)) {
      this.setState({
        loading: true,
        eventsByPlacesList: []
      });

      await this.loadEvents();

      this.setState({
        loading: false
      });
    }
  };


  /**
   * Component render
   */
  public render = () => {
    const mapOptions: MapOptions = {
      mapTypeControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true
    };
    
    const { customStyles, classes } = this.props;
    const { center, eventsByPlacesList, markerAnchorEl, loading } = this.state;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { loading ? (
          <Box 
            className={ classes.loaderContainer }
            style={ customStyles?.loaderContainer }
          >
            <CircularProgress color="primary" size={ 64 } />
          </Box>
      ) : (
        <GoogleMapReact
            draggable
            bootstrapURLKeys={{ key: config.googleMapAPIKey || "" }}
            center={ center }
            defaultCenter={{ lat: 62.799252, lng: 22.850832 }}
            defaultZoom={ 11 }
            options={ mapOptions }
            yesIWantToUseGoogleMapApiInternals
            onChange={({ center }) => {
              this.setState({
                onChangeCoords: center
              });
            }}
            onDragEnd={ this.onDragEnd }
            onZoomAnimationEnd={ this.onZoomAnimationEnd }
          >
            { eventsByPlacesList.map(eventsByPlace => {
              if (eventsByPlace.place.position && eventsByPlace.place.position.coordinates) {
                return (
                  <GoogleMapMarker
                    key={ eventsByPlace.placeId }
                    lat={ eventsByPlace.place.position.coordinates[0] }
                    lng={ eventsByPlace.place.position.coordinates[1] }
                    events={ eventsByPlace.events }
                    anchorEl={ markerAnchorEl } 
                    onMarkerClick={this.onMarkerClick}
                  />
                );
              }
              return undefined;
            })}
            { this.renderPopper() }
          </GoogleMapReact>
        )}
      </div>
    );
  }

  /**
   * Renders popper component
   */
  private renderPopper = () => {
    const { history } = this.props;
    const popperThreshold: number = 0.0005;
    const { center, onChangeCoords } = this.state;

    /**
     * Opens popper if it has AnchorEl set
     */
    const open: boolean = !!this.state.markerAnchorEl;

    if (
      center &&
      onChangeCoords &&
      Math.abs(center.lat - onChangeCoords.lat) < popperThreshold &&
      Math.abs(center.lng - onChangeCoords.lng) < popperThreshold
    ) {
      return (
        <PopperComponent
          open={ open }
          history={ history }
          onClosePopperClick={ this.onClosePopperClick }
          eventsInPopper={ this.state.eventsInPopper }
          markerAnchorEl={ this.state.markerAnchorEl }
          lat={ center.lat }
          lng={ center.lng }
        />
      );
    }

    return undefined;
  };

  /**
   * Gets events and parses those events by places
   *
   * @returns events, metaData
   */
  private loadEvents = async () => {
    const events = await this.fetchEvents(1);
    const eventsByPlacesList = this.parseEventsByPlace(events);

    this.setState({
      eventsByPlacesList: eventsByPlacesList
    });

  };

  /**
   * Method for fetching long term events
   */
  private fetchEvents = async (page: number): Promise<Event[]> => {
    const { accessToken } = this.props;
    const eventsApi = Api.getEventApi(accessToken!);
    const apiData = await eventsApi.eventList({ ...this.createFilterParams(), page: page });
    const metaData = apiData.meta || {};
    const events = apiData.data || [];

    if (metaData.next) {
        page++
        return events.concat(await this.fetchEvents(page))
    }

    return events;
}

  /**
   * Parses every event and creates new object by place id if place id doesn't hold object
   * before. Otherwise just updates existing list
   *
   * @param events
   * @return eventsByPlacesListCopy
   */
  private parseEventsByPlace = (events: Event[]): EventsByPlace[] => {
    const eventsByPlacesListCopy = [...this.state.eventsByPlacesList];
    events.forEach(event => {
      if (event.location.id !== undefined) {
        const placeId = event.location.id;

        const index = this.placeIsFoundAtIndex(placeId, eventsByPlacesListCopy);

        if (index > -1) {
          eventsByPlacesListCopy[index].events.push(event);
        } else {
          const newEventsByPlace: EventsByPlace = {
            placeId: placeId,
            place: event.location,
            events: [event]
          };

          eventsByPlacesListCopy.push(newEventsByPlace);
        }
      }
    });
    return eventsByPlacesListCopy;
  };

  /**
   * Checks if list contains place with given place id. Returns if index of founded place id
   *
   * @param placeId
   * @param eventsByPlaceList
   * @return index
   */
  private placeIsFoundAtIndex = (placeId: string, eventsByPlaceList: EventsByPlace[]): number => {
    for (let i = 0; i < eventsByPlaceList.length; i++) {
      if (eventsByPlaceList[i].placeId === placeId) {
        return i;
      }
    }

    return -1;
  };

  /**
   * Creates and returns filterParams object from filter object in state
   *
   * @returns filterParams object
   */
  private createFilterParams = (): EventListRequest => {

    const { filters } = this.props;

    const filterParams: EventListRequest = {
      include: ["location"],
      keyword: [...filters.selectedAudienceIds, ...filters.selectedCategoryIds].join(","),
      start: filters.dateStart,
      end: filters.dateEnd,
      addressLocalityFi: [...filters.selectedPlaceIds].join(","),
      page: 1,
      pageSize: filters.pageSize,
      sort: "start_time",
      text: filters.text
    };

    return filterParams;
  };

  /**
   * Handles marker click event
   */
  private onMarkerClick = (anchorEl: any, events: Event[], center: Coords) => {
    if (this.state.markerAnchorEl === anchorEl) {
      this.setState({
        markerAnchorEl: null,
        eventsInPopper: []
      });
    } else {
      this.setState({
        markerAnchorEl: anchorEl,
        eventsInPopper: events,
        center
      });
    }
  };

  /**
   * Handles popper close click
   */
  private onClosePopperClick = () => {
    this.setState({
      markerAnchorEl: null,
      eventsInPopper: [],
      center: undefined,
      onChangeCoords: undefined
    });
  };

  /**
   * Handles Google map on Drag end
   */
  private onDragEnd = () => {
    this.setState({
      markerAnchorEl: null,
      center: undefined,
      onChangeCoords: undefined
    });
  };

  /**
   * Handles Google map on Zoom animation end
   */
  private onZoomAnimationEnd = () => {
    this.setState({
      markerAnchorEl: null,
      center: undefined,
      onChangeCoords: undefined
    });
  };
}

/** 
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(AllEventsMap);
const CustomStyled = withCustomStyles("generic/all-events-maps")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
