import * as React from "react";

import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { withCustomStyles } from "../hocs/with-custom-styles";
import styles from "../../styles/generic/contact-info";
import { CustomStyles } from "../../types";
import strings from "../../localization/strings";

/**
 * Interface describing optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component properties
 */
export interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * Functional component for contact info
 * 
 * @param props component props
 */
const ContactInfo: React.FC<Props & OptionalProps> = ({
  classes,
  customStyles
}) => {

  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <Typography
        className={ classes.name }
        style={ customStyles?.name }
      >
        { strings.client.addressName }
      </Typography>
      <div 
        className={ classes.contactInfo }
        style={ customStyles?.contactInfo }
      >
        <p>
          Hallituskatu
          <span className="separator-horizontal" aria-hidden="true">___</span>
          7 A 21a,
          <span className="separator-horizontal" aria-hidden="true">___</span>
          50100 Mikkeli
        </p>
        <p>
          p. 044 290 9201
          <span className="separator-horizontal" aria-hidden="true">___</span>
          info@metatavu.fi
        </p>
      </div>
    </div>
  );
}

const Styled = withStyles(styles)(ContactInfo);
const CustomStyled = withCustomStyles("generic/contact-info")(Styled);

export default CustomStyled;