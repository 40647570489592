import * as React from "react";

import { IconButton, withStyles, WithStyles } from "@material-ui/core";
import { withCustomStyles } from "../hocs/with-custom-styles";
import styles from "../../styles/generic/social-media";
import { CustomStyles } from "../../types";

import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

/**
 * Interface describing optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component properties
 */
export interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * Functional component for social media
 * 
 * @param props component props
 */
const SocialMedia: React.FC<Props & OptionalProps> = ({
  classes,
  customStyles
}) => {

  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <IconButton href="https://twitter.com/EPliitto" target="_blank"  size="small">
        <TwitterIcon fontSize="large" htmlColor="#45c5fe" />
      </IconButton>
      <IconButton href="https://www.facebook.com/epliitto/" target="_blank" size="small">
        <FacebookIcon fontSize="large" htmlColor="#466eb6" />
      </IconButton>
    </div>
  );
}

const Styled = withStyles(styles)(SocialMedia);
const CustomStyled = withCustomStyles("generic/social-media")(Styled);

export default CustomStyled;