import * as React from "react";
import { styles } from "../../styles/screens/admin/editor-screen";
import { AccessToken, CustomStyles } from "../../types";
import Table from "..//generic/admin/table/table";
import GenericDrawer from "../generic/generic-drawer";
import { IKeywordColumnData } from "../../types/index";
import EventUtils from "../../utils/event-utils";
import { Keyword, KeywordName, KeywordSet, KeywordSetName, PostKeywordSet } from "../../generated/client";
import strings from "../../localization/strings";
import { Container, TextField, WithStyles, withStyles, CircularProgress, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import GenericConfirmDialog from "../generic/generic-confirm-dialog";
import theme from "../../dynamic-content/theme";
import { Message } from "../screens/event-screen";
import { SnackbarMessage } from "../layouts/app-layout";
import GenericSnackbar from "../generic/generic-snackbar";
import Api from "../../api/api";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { Config } from "../../constants/configuration";

const config = Config.getConfig();

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  accessToken: AccessToken;
}

/**
 * Interface describing component state
 */
interface State {
  columns: IKeywordColumnData[];
  editId?: string;
  editName?: KeywordName;
  editKeywordSetIds: string[];
  drawerOpen: boolean;
  deleteConfirmOpen: boolean;
  tableData: RowData[];
  loading: boolean;
  snackbarMessage?: SnackbarMessage;
  keywordSets: KeywordSet[];
}

/**
 * Describes the table row data
 */
interface RowData {
  displayId: string;
  id: string;
  fi?: string;
  en?: string;
}

/**
 * Component for keywords screen
 */
class AdminKeywordsTab extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      /**
       * title: Column name that is displayed
       * field: The value that is expected from the object
       * editable: if the field should be editable or not
       * render: assigns a custom render
       * hidden: hides column
       */
      columns: [
        { title: 'ID', field: 'displayId' },
        { title: 'Suomi', field: 'fi' },
        { title: 'Englanti', field: 'en' }
      ],
      drawerOpen: false,
      deleteConfirmOpen: false,
      tableData: [],
      loading: false,
      snackbarMessage: this.getSnackbarMessage(undefined),
      keywordSets: [],
      editKeywordSetIds: []
    };
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () => {
    this.setState({
      loading: true
    });

    const keywordSets = await this.loadKeywordSets();
    await this.fetchKeywords();

    this.setState({
      keywordSets: keywordSets,
      loading: false
    });
  }

  /**
   * Component render method
   */
  public render = () => {
    const { customStyles, classes } = this.props;
    const { columns, drawerOpen, tableData, editId, loading, snackbarMessage } = this.state;

    if (loading) {
      return (
        <div
          className={ classes.loaderContainer }
          style={ customStyles?.loaderContainer }
        >
          <CircularProgress size={ 80 }/>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div 
          className={ classes.container } 
          style={{ display: loading ? "none" : "block" , ...customStyles?.container }} 
        >
          <Table
            columns={ columns }
            data={ tableData }
            showAddButton={ true }
            onAddButtonClick={ this.onAddButtonClick }
            currentRowSelect={ this.onTableRowSelect }
          />
          <GenericDrawer
            open={ drawerOpen }
            title={ editId ? strings.admin.updateKeyword : strings.admin.newKeyword }
            onDeleteClick={ this.onDeleteClick }
            onCancelClick={ this.onCancelClick }
            onSaveClick={ this.onSaveClick }
            onCloseClick={ this.onCloseClick }
            onClose={ this.onCloseClick }
          >
            { this.renderForm() }
          </GenericDrawer>
          <GenericSnackbar snackbarMessage={ snackbarMessage } clearSnackbar={ this.clearSnackbar } />
          { this.renderDeleteDialog() }
        </div>
      </React.Fragment>
    );
  }

  /**
   * Renders form components
   */
  private renderForm = () => {
    const { customStyles, classes } = this.props;
    const { editName, drawerOpen } = this.state;

    if (!drawerOpen) {
      return null;
    }

    return (
      <div style={{ marginTop: theme.spacing(2) }}>
        <Container 
          className={ classes.container }
          style={ customStyles?.container }
        >
          <TextField
            label={ strings.admin.finnishKeywordLabel }
            name="fi"
            onChange={ event => this.onInputChange(event, "fi") }
            value={ editName?.fi || "" }
          />
          <TextField
            label={ strings.admin.englishKeywordLabel }
            name="en"
            onChange={ event => this.onInputChange(event, "en") }
            value={ editName?.en || "" }
          />
          { this.renderKeywordSets() }
        </Container>
      </div>
    )
  }

  /**
   * Renders keyword sets
   */
  private renderKeywordSets = () => {
    const { customStyles, classes } = this.props;

    return (
      <>
        <Typography 
          className={ classes.title }
          style={ customStyles?.title } 
          variant="h5"
        >
          { strings.admin.keywordSets }
        </Typography>
        { this.state.keywordSets.map(this.renderKeywordSet) }
      </>
    );
  }

  /**
   * Renders keyword set
   */
  private renderKeywordSet = (keywordSet: KeywordSet) => {
    const { editKeywordSetIds } = this.state;

    if (!editKeywordSetIds) {
      return null;
    }

    const keywordSetId = keywordSet.id;
    const checked = editKeywordSetIds.includes(keywordSetId);

    return (
      <div>
        <FormControlLabel
          key={ keywordSetId }
          control={
            <Checkbox
              checked={ checked }
              onChange={ () => this.onKeywordSetCheckboxChange(keywordSetId) }
            />
          }
          label={ keywordSet.name[strings.getLanguage() as keyof KeywordSetName] }
        />
      </div>
    );
  }

  /** 
   * Renders delete dialog popup
   */
  private renderDeleteDialog = () => {
    const { deleteConfirmOpen } = this.state;

    return (
      <GenericConfirmDialog 
        positiveButtonText={ strings.admin.delete }
        cancelButtonText={ strings.admin.cancel }
        title={ strings.admin.dialogDeleteKeywordTitle }
        description={ strings.admin.dialogDeleteKeywordDescription}
        onConfirm={ this.onDeleteConfirmDialogConfirm }
        onCancel={ this.onDeleteConfirmDialogCancel }
        open={ deleteConfirmOpen }
      />
    );
  }

  /**
   * Returns keywords as table data
   * 
   * @param keywords keywords
   * @return table data
   */
  private getTableData = (keywords: Keyword[]): RowData[] => {
    return keywords
      .filter(keyword =>
        !keyword.name.fi?.includes("Automaattinen luonnos") &&
        !keyword.name.en?.includes("Automaattinen luonnos")
      )
      .map(keyword => {
        return {
          displayId: this.getDisplayId(keyword.id),
          id: keyword.id,
          fi: keyword.name.fi,
          en: keyword.name.en
        };
    });
  }

  /**
   * Returns id as displayable id
   * 
   * @param id id
   * @return displayable id
   */
  private getDisplayId = (id: string) => {
    return id.split("").reverse().join("").substr(1, 10).split("").reverse().join("");
  }

  /**
   * Returns keyword ids from given keyword set
   * 
   * @param keywordSet keyword set
   * @return keyword ids
   */
  private getKeywordSetKeywordIds = (keywordSet: KeywordSet): string[] => {
    return keywordSet.keywords
      .map(keyword => keyword.id)
      .map(EventUtils.parseIdFromUrl);
  }

  /**
   * Loads keyword sets from the API
   * 
   * @return keyword sets
   */
  private loadKeywordSets = async (): Promise<KeywordSet[]> => {
    const { accessToken } = this.props;

    const filterApi = Api.getFilterApi(accessToken);
    return (await filterApi.keywordSetList({ include: ["keywords"] })).data || [];
  }
  
  /**
   * Gets all keywords
   */
  private fetchKeywords = async () => {
    const { accessToken } = this.props;

    const keywords = await EventUtils.getAllKeywords(accessToken);
    this.setState({ 
      tableData: this.getTableData(keywords)
    });
  }

  /**
   * Creates new Keyword 
   */
  private createKeyword = async () => {
    const { accessToken } = this.props;
    const { editName, editKeywordSetIds, keywordSets } = this.state;

    if (!accessToken || !editName || !editKeywordSetIds) {
      return;
    }

    this.setState({
      loading: true
    });

    const newKeyword: Keyword = {
      id: config.dataSource + ":" + Math.random().toString(36).substr(2, 10),
      name: editName,
      originId: undefined,
      publisher: undefined,
      createdTime: new Date(),
      aggregate: true,
      dataSource: config.dataSource,
      createdBy: config.eventPublisher,
      lastModifiedBy: undefined
    }

    try {
      const keyword = await EventUtils.createNewKeyword(newKeyword, accessToken);
      const filterApi = Api.getFilterApi(accessToken);

      for (let i = 0; i < editKeywordSetIds.length; i++) {
        const editKeywordSetId = editKeywordSetIds[i];
        const keywordSet = keywordSets.find(item => item.id === editKeywordSetId) as PostKeywordSet;
        keywordSet.keywords = [ ...keywordSet.keywords, keyword ];

        await filterApi.keywordSetUpdate({
          id: keywordSet.id,
          keywordSetObject: { ...keywordSet }
        });
      }
      
      this.setState({
        snackbarMessage: this.getSnackbarMessage("edited"),
        drawerOpen: false
      })
    } catch (e) {
      console.error("Error creating keyword: ", e);
      this.setState({
        snackbarMessage: this.getSnackbarMessage("no-permission"),
      })
    }

    await this.fetchKeywords();

    this.setState({
      loading: false
    });
  }

  /**
   * Updates the Keyword 
   */
  private updateKeyword = async () => {
    const { accessToken } = this.props;
    const { editId, editName, editKeywordSetIds, keywordSets } = this.state;

    if (!accessToken || !editId || !editName || !editKeywordSetIds) {
      return;
    }

    this.setState({
      loading: true
    });

    const id = EventUtils.parseIdFromUrl(editId);

    const updatedKeyword: Keyword = {
      id: id,
      name: editName,
      originId: undefined,
      publisher: undefined,
      lastModifiedTime: new Date(),
      aggregate: true,
      dataSource: config.dataSource,
      createdBy: config.eventPublisher,
      lastModifiedBy: undefined
    }

    try {
      await EventUtils.updateExistingKeyword(id, updatedKeyword, accessToken);
      const filterApi = Api.getFilterApi(accessToken);

      for (let i = 0; i < keywordSets.length; i++) {
        const keywordSet = keywordSets[i];
        const keywordIds = this.getKeywordSetKeywordIds(keywordSet);
        const keywordSetIncluded = editKeywordSetIds.includes(keywordSet.id);
        const keywordIncluded = keywordIds.includes(id);
        
        if (keywordSetIncluded !== keywordIncluded) {
          if (keywordSetIncluded) {
            keywordSet.keywords = [ ...keywordSet.keywords, { ...updatedKeyword, id: editId }];
          } else {
            keywordSet.keywords = keywordSet.keywords.filter(item => item.id !== editId);
          }

          await filterApi.keywordSetUpdate({
            id: keywordSet.id,
            keywordSetObject: { ...keywordSet } as PostKeywordSet
          });
        }
      }

      this.setState({
        snackbarMessage: this.getSnackbarMessage("edited"),
        drawerOpen: false
      })
    } catch (e) {
      this.setState({
        snackbarMessage: this.getSnackbarMessage("no-permission")
      })
      console.error("Error updating keyword: ", e);
    }

    await this.fetchKeywords();

    this.setState({
      loading: false
    });
  }

  /**
   * Event handler for keyword set checkbox value change
   * 
   * @param keywordSetId keyword set id
   */
  private onKeywordSetCheckboxChange = (keywordSetId: string) => {
    const { editKeywordSetIds } = this.state;

    if (!editKeywordSetIds) {
      return;
    }

    if (editKeywordSetIds.includes(keywordSetId)) {
      this.setState({
        editKeywordSetIds: editKeywordSetIds.filter(id => id !== keywordSetId)
      });
    } else {
      this.setState({
        editKeywordSetIds: [ ...editKeywordSetIds, keywordSetId ]
      });
    }
  }

  /**
   * Handles text field change events
   * 
   * @param event React change event
   * @param locale locale
   */
  private onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, locale: keyof KeywordName) => {
    const { editName } = this.state;
    const updatedName: KeywordName = { ...editName };
    updatedName[locale] = event.target.value;

    this.setState({
      editName: updatedName
    });
  }

  /**
   * Event handler for confirm dialog confirm click
   */
  private onDeleteConfirmDialogConfirm = async () => {
    const { accessToken } = this.props;
    const { editId, tableData } = this.state;

    this.setState({
      drawerOpen: false,
      loading: true,
      deleteConfirmOpen: false
    });

    if (!editId) {
      return;
    }

    const id = EventUtils.parseIdFromUrl(editId);

    try {
      await EventUtils.deleteExistingKeyword(id, accessToken);
      this.setState({
        snackbarMessage: this.getSnackbarMessage("delete"),
        drawerOpen: false
      })
    } catch (error) {
      console.log(error);
      this.setState({
        snackbarMessage: this.getSnackbarMessage("no-permission"),
      })
    }

    this.setState({
      editId: undefined,
      loading: false,
      tableData: tableData.filter(tableRow => tableRow.id !== editId)
    });
  }

  /**
   * Event handler for confirm dialog cancel click
   */
  private onDeleteConfirmDialogCancel = () => {
    this.setState({
      deleteConfirmOpen: false
    });
  }

  /**
   * Event handler for keyword deletion click
   */
  private onDeleteClick = () => {
    this.setState({
      deleteConfirmOpen: true
    });
  }

  /**
   * Event handler for cancel editing / adding keyword click
   */
  private onCancelClick = () => {
    this.setState({
      drawerOpen: false
    });
  }

  /**
   * Event handler for keyword save click
   */
  private onSaveClick = async () => {
    const { editId } = this.state;

    if (!editId) {
      await this.createKeyword();
    } else {
      await this.updateKeyword();
    }
  }

  /**
   * Event handler for drawer close click
   */
  private onCloseClick = () => {
    this.setState({
      drawerOpen: false
    });
  }
  
  /**
   * Event handler for table row select
   * 
   * @param data selected row data
   */
  private onTableRowSelect = (data?: RowData) => {
    const { keywordSets } = this.state;
    
    const editId = data?.id;
    if (!editId) {
      return;
    }

    const id = EventUtils.parseIdFromUrl(editId);

    const editKeywordSetIds = keywordSets
      .filter(keywordSet => this.getKeywordSetKeywordIds(keywordSet).includes(id))
      .map(keywordSet => keywordSet.id);

    this.setState({
      editId: editId,
      editName: {
        en: data?.en,
        fi: data?.fi
      },
      editKeywordSetIds: editKeywordSetIds,
      drawerOpen: true
    });
  }

  /**
   * Event handler for side menu toggle
   */
  private onAddButtonClick = () => {
    this.setState({
      drawerOpen: true,
      editId: undefined,
      editName: {} as any
    });
  }

   /**
   * Clears the snackbar message
   */
  private clearSnackbar = () => {
    this.setState({
      snackbarMessage: undefined
    });
  }

  /**
   * Returns snackbar message for given message object
   * 
   * @param message message
   * @return snackbar message
   */
  private getSnackbarMessage = (message?: Message): SnackbarMessage | undefined => {
    if (!message) {
      return undefined;
    }

    switch (message) {
      case "created":
        return {
          message: strings.keyword.keywordAddedSuccess,
          severity: "success"
        }
      case "edited":
        return {
          message: strings.keyword.keywordEditSuccess,
          severity: "success"
        }
      case "no-permission":
        return {
          message: strings.errors.failMessage,
          severity: "error"
        }
      case "delete":
        return {
          message: strings.keyword.keywordDeleteSuccess,
          severity: "success"
        }
    }
  }
}

const Styled = withStyles(styles)(AdminKeywordsTab);
const CustomStyled = withCustomStyles("admin/admin-keywords-tab")(Styled);

export default CustomStyled;
