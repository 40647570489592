import * as React from 'react';

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";
import Api from "../../api/api";
import { Box, Typography, withStyles, WithStyles } from "@material-ui/core";
import AppLayout from "../layouts/app-layout";
import styles from "../../styles/screens/home-screen";
import { History } from "history";
import { KeycloakInstance } from 'keycloak-js';
import { NullableToken, CustomStyles } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
import strings from '../../localization/strings';
import EventLister from "../event/event-lister";
import { Event } from "../../generated/client";
import GenericConfirmDialog from "../generic/generic-confirm-dialog";
import EventUtils from "../../utils/event-utils";
import { Config } from "../../constants/configuration";

const config = Config.getConfig();

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  keycloak?: KeycloakInstance;
  history: History<History.LocationState>;
}

/**
 * Component state
 */
interface State {
  loading: boolean;
  deleteConfirmOpen: boolean;
  deleteEvent?: Event;
  events: Event[];
}

/**
 * Profile screen component 
 */
class ProfileScreen extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      deleteConfirmOpen: false,
      events: []
    }
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () =>  {
    const { accessToken, keycloak } = this.props;

    if (!accessToken && keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }

    this.setState({
      loading: true
    });

    const events = await this.loadEvents();

    this.setState({
      loading: false,
      events: events
    });
  }

  /**
   * Component did update life-cycle handler
   */
  public componentDidUpdate = () => {
    const { accessToken, keycloak } = this.props;

    if (!accessToken && keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, history } = this.props;
    const { events, loading } = this.state;

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container}
        >
          <Typography
            variant="h1"
            className={ classes.heading }
            style={ customStyles?.heading }
          >
            { strings.profile.pageTitle }
          </Typography>
          <div 
            className={ classes.content }
            style={ customStyles?.content }
          >
            <Box pl={ 4 } pr={ 4 }>
              <EventLister
                history={ history }
                events={ events }
                title={ strings.profile.eventsTitle }
                disabled={ false }
                loading={ loading }
                showEditButtons={ true }
                deleteButtonText={ strings.profile.delete }
                copyButtonText={ strings.profile.copy }
                editButtonText={ strings.profile.edit }
                onCopyClick={ this.onEventCopyClick }
                onDeleteClick={ this.onEventDeleteClick }
                onEditClick={ this.onEventEditClick }
              />
            </Box>
          </div>
        </div>
        { this.renderDeleteDialog() }
      </AppLayout>
    )
  }

  /** 
   * Renders delete dialog popup
   */
  private renderDeleteDialog = () => {
    const { deleteConfirmOpen } = this.state;

    return (
      <GenericConfirmDialog 
        positiveButtonText={ strings.profile.yes }
        cancelButtonText={ strings.admin.cancel }
        title={ strings.admin.dialogDeleteEventTitle }
        description={ strings.admin.dialogDeleteEventDescription}
        onConfirm={ this.onDeleteConfirmDialogConfirm }
        onCancel={ this.onDeleteConfirmDialogCancel }
        open={ deleteConfirmOpen }
      />
    );
  }
  
  /**
   * Method for fetching short term events
   */
  private loadEvents = async (): Promise<Event[]> => {
    try {
      const { accessToken } = this.props;
      const userId = accessToken?.userId;
      const dataSource = config.dataSource
      const eventsApi = Api.getEventApi(accessToken);
      const eventsData = await eventsApi.eventList({ 
        sort: "start_time",
        publisher: `${dataSource}:oidc:user:${userId}`
      });

      const data = eventsData?.data || [];
      return data.filter(event => event.superEvent === undefined);
    } catch (error) {
      // TODO: Proper error handling
      console.log(error);
    }

    return [];
  }

  /**
   * Event copy click listener
   * 
   * @param event event
   */
  private onEventCopyClick = (event: Event) => {
    const { history } = this.props;
    history.push(`/add-event?copy-event-id=${event.id}`);
  }

  /**
   * Event edit click listener
   * 
   * @param event event
   */
  private onEventEditClick = (event: Event) => {
    const { history } = this.props;
    history.push(`/edit-event/${event.id}`);
  }

  /**
   * Event delete click listener
   * 
   * @param event event
   */
  private onEventDeleteClick = (event: Event) => {
    this.setState({
      deleteConfirmOpen: true,
      deleteEvent: event
    });
  }

  /**
   * Event handler for confirm dialog confirm click
   */
  private onDeleteConfirmDialogConfirm = async () => {
    const { accessToken } = this.props;
    const { deleteEvent, events } = this.state;

    if (!deleteEvent) {
      return;
    }

    this.setState({
      loading: true,
      deleteConfirmOpen: false,
      deleteEvent: undefined
    });

    EventUtils.deleteExistingEvent(deleteEvent, accessToken);

    this.setState({
      loading: false,
      events: events.filter(event => event.id !== deleteEvent.id)
    });
  }

  /**
   * Event handler for confirm dialog cancel click
   */
  private onDeleteConfirmDialogCancel = () => {
    this.setState({
      deleteConfirmOpen: false,
      deleteEvent: undefined
    });
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  locale: state.locale.locale,
  keycloak: state.auth.keycloak,
  accessToken: state.auth.accessToken
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(ProfileScreen)
const CustomStyled = withCustomStyles("screens/profile-screen")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
