import * as React from "react";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/screens/admin-screen";
import { History } from "history";
import Header from "../generic/admin/admin-header";
import AdminEventsTab from "../admin/admin-events-tab";
import AdminKeywordsTab from "../admin/admin-keywords-tab";
import AdminPlacesTab from "../admin/admin-places-tab";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxActions, ReduxState } from "../../store";
import { NullableToken, CustomStyles } from "../../types";
import strings from "../../localization/strings";
import { KeycloakInstance } from "keycloak-js";
import { withCustomStyles } from "../hocs/with-custom-styles";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  keycloak?: KeycloakInstance;
  accessToken?: NullableToken;
  history: History<History.LocationState>;
}

/**
 * Interface describing component state
 */
interface State {
  tabIndex: number;
}

/**
 * Component for admin screen
 */
class AdminScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      tabIndex: 0
    };
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = () => {
    const { accessToken, keycloak } = this.props;

    if (!accessToken && keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }
  }

  /**
   * Component did update life-cycle handler
   */
  public componentDidUpdate = () => {
    const { accessToken, keycloak } = this.props;

    if (!accessToken && keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { customStyles, classes, keycloak, accessToken } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className={ classes.container }
      style={ customStyles?.container }>
        <Header
          tabIndex={ tabIndex }
          keycloak={ keycloak }
          accessToken={ accessToken }
          handleTabChange={ this.setTabIndex }
        />
        { this.renderContents() }
      </div>
    );
  }

  /**
   * Renders component contents
   */
  private renderContents = () => {
    const { 
      accessToken
    } = this.props;
    
    const { tabIndex } = this.state;

    if (!accessToken) {
      return null;
    }

    if (!accessToken.realmRoles.includes("admin")) {
      return (
        <Typography>{ strings.errors.permissionDenied }</Typography>
      );
    }

    switch (tabIndex) {
      case 0:
        return (
          <AdminEventsTab 
            accessToken={ accessToken }
          />
        );
      case 1:
        return (
          <AdminKeywordsTab 
            accessToken={ accessToken }
          />
        );
      case 2:
        return (
          <AdminPlacesTab 
            accessToken={ accessToken }
          />
        );
    }
  }

  /**
   * Sets tab index
   * 
   * @param event event object
   * @param newValue new tab index value
   */
  private setTabIndex = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    });
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  keycloak: state.auth.keycloak,
  accessToken: state.auth.accessToken
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
});

const Styled = withStyles(styles)(AdminScreen)
const CustomStyled = withCustomStyles("screens/admin-screen")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;