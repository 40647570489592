import * as React from "react";
import { withStyles, WithStyles, Snackbar } from "@material-ui/core";
import styles from "../../styles/layouts/app-layout";
import Header, { OptionalProps as HeaderProps } from "../generic/header";
import Footer, { OptionalProps as FooterProps } from "../generic/footer";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import MuiAlert from '@material-ui/lab/Alert';

/**
 * Snackbar message data
 */
export interface SnackbarMessage { 
  message: string; 
  severity: "success" | "info" | "warning" | "error";
}

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  snackbarMessage?: SnackbarMessage;
  headerProps?: HeaderProps;
  footerProps?: FooterProps;
  customStyles?: CustomStyles;
  clearSnackbar?: () => void;
}

/**
 * App layout component
 *
 * @param props component props
 */
const AppLayout: React.FC<Props> = ({
  classes,
  customStyles,
  children,
  headerProps,
  footerProps,
  snackbarMessage,
  clearSnackbar
}) => {
  return (
    <div 
      className={ classes.container }
      style={ customStyles?.container }
    >
      <Header { ...headerProps }/>
      <div
        className={ classes.content }
        style={ customStyles?.content }
      >
        { children }
      </div>
      <div
        className={ classes.banner }
        style={ customStyles?.banner }
      >
      </div>
      <Footer { ...footerProps }/>
      <Snackbar open={ !!snackbarMessage } autoHideDuration={ 6000 } onClose={ clearSnackbar } anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <MuiAlert elevation={ 12 } variant="filled" onClose={ clearSnackbar } severity={ snackbarMessage?.severity }>
          { snackbarMessage?.message || "" }
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

const Styled = withStyles(styles)(AppLayout);
const CustomStyled = withCustomStyles("layouts/app-layout")(Styled);

export default CustomStyled;