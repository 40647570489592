import * as React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import moment from "moment";
import Api from "../../api/api";
import EventForm from "../event/event-form";
import { History, Location } from "history";
import { KeycloakInstance } from "keycloak-js";
import strings from "../../localization/strings";
import EventUtils from "../../utils/event-utils";
import styles from "../../styles/screens/create-event-screen";
import { withCustomStyles } from "../hocs/with-custom-styles";
import AppLayout, { SnackbarMessage } from "../layouts/app-layout";
import { NullableToken, CustomStyles, Localizations, EventPeriod } from "../../types";
import { PostEvent, Event, Offer, Place, Image, Eventlink } from "../../generated/client";
import { withStyles, WithStyles, Paper, Button, Box, CircularProgress } from "@material-ui/core";

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  eventId?: string;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  keycloak?: KeycloakInstance;
  history: History<History.LocationState>;
  location: Location<History.LocationState>;
}

/**
 * Component state
 */
interface State {
  places: Place[];
  loading: boolean;
  event: PostEvent;
  division: string;
  eventImage?: Image;
  formValid: boolean;
  eventLocation?: Place;
  placesLoaded: boolean;
  eventImagePreview?: Image;
  eventPeriods: EventPeriod[];
  localizations: Localizations;
  mode: "new" | "copy" | "edit";
  showValidationMessages: boolean;
  snackbarMessage?: SnackbarMessage;
}

/**
 * Create event screen component 
 */
class CreateOrUpdateEventScreen extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);

    const defaultTime = moment().minutes(0).seconds(0).toDate();

    this.state = {      
      mode: "new",
      places: [],
      eventPeriods: [],
      placesLoaded: true,
      division: "",
      loading: false,
      formValid: false,
      showValidationMessages: false,
      localizations: {
        fi: true,
        sv: false,
        en: false
      },
      event: {
        name: {
          fi: "",
          sv: "",
          en: ""
        },
        description: {
          fi: "",
          sv: "",
          en: ""
        },
        shortDescription: {
          fi: "",
          sv: "",
          en: ""
        },
        offers: [
          {
            isFree: false,
            price: {
              fi: "",
              sv: "",
              en: ""
            },
            infoUrl: {
              fi: "",
              sv: "",
              en: ""
            },
            description: {
              fi: "",
              sv: "",
              en: ""
            }
          }
        ],
        images: [
          {
            id: ""
          }
        ],
        startTime: defaultTime,
        endTime: defaultTime,
        customData: {
          contact_name: "",
          contact_phone: "",
          contact_email: "",
          provider_name: "",
          provider_phone: "",
          provider_email: "",
          other_attachments: "[]",
          registration_description: `{
            "fi": "",
            "sv": "",
            "en": ""
          }`
        },
        location: {
          id: ""
        },
        provider: {
          fi: "",
          sv: "",
          en: ""
        },
        keywords: [],
        publicationStatus: "public",
        externalLinks: [
          {
            name: "YouTube",
            link: "",
            language: "fi"
          },
          {
            name: "Twitter",
            link: "",
            language: "fi"
          },
          {
            name: "Facebook",
            link: "",
            language: "fi"
          },
          {
            name: "Instagram",
            link: "",
            language: "fi"
          },
          {
            name: "Instagram HashTag",
            link: "",
            language: "fi"
          },
          {
            name: "Instagram HashTag",
            link: "",
            language: "fi"
          },
          {
            name: "YouTube Or Vimeo Link",
            link: "",
            language: "fi"
          },
          {
            name: "Event Organizer Link",
            link: "",
            language: "fi"
          }
        ]
      },
    }
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () =>  {
    const { accessToken, keycloak } = this.props;

    if (!accessToken && keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }

    await this.loadPage();
  }

  /**
   * Component did update life-cycle handler
   *
   * @param prevProps previous component properties
   */
  public componentDidUpdate = async (prevProps: Props) =>  {
    const { accessToken, keycloak } = this.props;

    if (!accessToken && keycloak) {
      keycloak.login({ idpHint: "oidc" });
    }

    if (this.props.eventId !== prevProps.eventId) {
      await this.loadPage();
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;
    const { snackbarMessage } = this.state;

    return (
      <AppLayout
        snackbarMessage={ snackbarMessage }
        clearSnackbar={ this.clearSnackbar }
      >
        <Box
          className={ classes.container }
          style={ customStyles?.container}
        >
          <Paper
            className={ classes.paper }
            style={ customStyles?.paper }
          >
            { this.renderContent() }
          </Paper>
        </Box>
      </AppLayout>
    )
  }

  /**
   * Method for loading page
   */
  private loadPage = async () => {
    const { eventId, accessToken, location, history } = this.props;

    if (!accessToken) {
      return;
    }

    const query = new URLSearchParams(location.search);
    const copyEventId = query.get("copy-event-id");
    const loadEventId = eventId || copyEventId;
    
    this.setState({
      loading: true,
      mode: eventId ? "edit" :  copyEventId ? "copy" : "new"
    });

    if (loadEventId) {
      const eventApi = Api.getEventApi(accessToken);
      const imageApi = Api.getImageApi(accessToken);
      const filterApi = Api.getFilterApi(accessToken);
      const retrievedEvent = await eventApi.eventRetrieve({ id: loadEventId });
      const superEventId = EventUtils.parseIdFromUrl(retrievedEvent.superEvent?.id || "");
      const event = this.initRequiredValues(retrievedEvent);
      const imageUrl = event.images && event.images.length ? event.images[0].id : undefined;
      const imageId = EventUtils.parseIdFromUrl(imageUrl || "");
      const image: Image = await imageApi.imageRetrieve({ id: imageId });
      const placeUrl = event.location.id;
      const placeId = EventUtils.parseIdFromUrl(placeUrl || "");
      const place: Place = await filterApi.placeRetrieve({ id: placeId });
      const eventPeriods = await EventUtils.getEventPeriods(event, accessToken);
      const localizations = {
        fi: !!event.name["fi"],
        sv: !!event.name["sv"],
        en: !!event.name["en"]
      };

      if (superEventId) {
        history.push(`/edit-event/${ superEventId }`);
      } else if (eventId && !EventUtils.canEditEvent(accessToken, event)) {
        history.push(`/event/${event.id}?message=no-permission`);
      } else {
        this.setState({
          event: event,
          eventImage: image,
          eventLocation: place,
          eventPeriods: eventPeriods,
          localizations: localizations,
          division: place.addressLocality?.fi || ""
        });
      }
    }

    await this.fetchPlaces();

    this.setState({
      loading: false
    });
  }

  /**
   * Method for rendering contents
   */
  private renderContent = () => {
    const { customStyles, classes } = this.props;
    const {
      mode,
      places,
      loading,
      division,
      eventImage,
      eventPeriods,
      placesLoaded,
      localizations,
      eventLocation,
      eventImagePreview,
      showValidationMessages
    } = this.state;

    if (loading) {
      return (
        <div 
          className={ classes.loaderContainer }
          style={ customStyles?.loaderContainer }
        >
          <CircularProgress 
            className={ classes.loader }
            style={ customStyles?.loader }
          />
        </div>
      );
    }

    return (
      <>
        <EventForm
          showHelp={ true }
          places={ places }
          division={ division }
          eventImage={ eventImage }
          event={ this.state.event }
          eventPeriods={ eventPeriods }
          placesLoaded={ placesLoaded }
          eventLocation={ eventLocation }
          localizations={ localizations }
          onPlaceUpdate={ this.onPlaceUpdate }
          onEventUpdate={ this.onEventUpdate }
          eventImagePreview={ eventImagePreview }
          onValidUpdate={ this.onFormValidUpdate }
          onChangeEventImage={ this.setEventImage }
          onDivisionUpdate={ this.onDivisionUpdate }
          onRemoveEventImage={ this.removeEventImage }
          setSnackbarMessage={ this.setSnackbarMessage }
          showValidationMessages={ showValidationMessages }
          onLocalizationUpdate={ this.onLocalizationUpdate }
          onUpdateEventPeriods={ this.onUpdateEventPeriods }
          onChangeEventImagePreview={ this.setEventImagePreview }
          title={ mode === "edit" ? strings.event.editEvent : strings.event.addNewEvent }
        />
        <Button onClick={ this.onSubmitClick }>{ this.renderButtonText() }</Button>
      </>
    );
  }

  /**
   * Method for rendering button text
   *
   * @returns string
   */
  private renderButtonText = (): string => {
    const { mode } = this.state;
    return mode === "edit" ? strings.event.save : strings.event.addEvent;
  }

  /**
   * Method for initializing event custom data
   *
   * @param event linked events event
   * @returns event linked events event
   */
  private initRequiredValues = (event: Event): PostEvent => {
    return {
      ...event,
      offers: this.getOffers(event),
      customData: event.customData
    };
  }

  /**
   * Method for getting offers
   *
   * @param event linked events event
   * @returns offer array
   */
  private getOffers = (event: PostEvent): Offer[] => {
    const { offers } = event;

    if (!offers) {
      return [];
    }

    return offers.map(offer => {

      if (!offer.description) {
        offer.description = {
          fi: "",
          sv: "",
          en: ""
        };
      }

      if (!offer.infoUrl) {
        offer.infoUrl = {
          fi: "",
          sv: "",
          en: ""
        };
      }

      return offer;
    });
  }

  /**
   * Method for adding event
   *
   * @param event linked events event
   */
  private addEvent = async (event: PostEvent) => {
    const { accessToken, history } = this.props;
    const { eventPeriods } = this.state;

    if (!accessToken) {
      return;
    }

    event.id = undefined;
    event.audience = [];
    event.externalLinks = this.filterEmptyLinks(event.externalLinks || []);

    try {
      const createdEvent = await EventUtils.createNewEvent(event, eventPeriods, accessToken);
      createdEvent.externalLinks = [
        ...createdEvent.externalLinks ?? [],
        {
          language: "fi",
          name: "EP Kalenteri",
          link: `${ window.location.origin }/event/${ EventUtils.parseIdFromUrl(createdEvent.id || "") }` 
        }
      ];
      await EventUtils.updateExistingEvent(createdEvent.id || "", createdEvent, eventPeriods, accessToken);
      history.push(`/event/${ createdEvent.id }?message=created`);
    } catch (error) {
      console.log(error);
      this.setSnackbarMessage({
        message: strings.validator.validatorHasFailed,
        severity: "error"
      });
    }
  }

  /**
   * Method for editing event
   *
   * @param event linked events event
   */
  private editEvent = async (event: PostEvent) => {
    const { accessToken, history } = this.props;
    const { eventPeriods } = this.state;

    if (!event.id) {
      return;
    }

    event.audience = [];
    event.externalLinks = this.filterEmptyLinks(event.externalLinks || []);

    try {
      await EventUtils.updateExistingEvent(event.id, event, eventPeriods, accessToken);
      history.push(`/event/${event.id}?message=edited`);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Method for setting event image
   *
   * @param eventImage event image
   */
  private setEventImage = (eventImage: Image) => {
    const { event } = this.state;

    event.images = [
      {
        id: eventImage.id
      }
    ];

    this.setState({ event, eventImage });
  }

  /**
   * Method for removing empty links
   *
   * @param externalLinks external links
   * @returns event link array
   */
  private filterEmptyLinks = (externalLinks: Eventlink[]): Eventlink[] => {
    return externalLinks
      .filter((item: Eventlink) => !!item.link)
      .map((item: Eventlink) =>
        /^https?:\/\//g.test(item.link || "") ? item : {...item, link: `https://${ item.link }`}
      );
  }

  /**
   * Method for removing event image
   */
  private removeEventImage = () => {
    const { event } = this.state;

    event.images = [];

    this.setState({ event, eventImage: undefined });
  }

  /**
   * Method for setting event image preview
   *
   * @param eventImagePreview event image preview
   */
  private setEventImagePreview = (eventImagePreview: Image) => {
    this.setState({ eventImagePreview });
  }

  /**
   * Method for setting snackbar messsage
   *
   * @param message message
   */
  private setSnackbarMessage = (message: SnackbarMessage) => {
    this.setState({
      snackbarMessage: message
    });
  }

  /**
   * Clears the snackbar message
   */
  private clearSnackbar = () => {
    this.setState({
      snackbarMessage: undefined
    });
  }

  /**
   * Event handler for event update
   * 
   * @param event event
   */
  private onEventUpdate = (event: PostEvent) => {
    this.setState({ event: event });    
  }

  /**
   * Event handler for form valid update
   * 
   * @param valid valid
   */
  private onFormValidUpdate = (valid: boolean) => {
    this.setState({
      formValid: valid
    });
  }

  /**
   * Event handler for localization update
   * 
   * @param localizations localizations
   */
  private onLocalizationUpdate = (localizations: Localizations) => {
    this.setState({
      localizations: { ...localizations }
    });
  }

  /**
   * Method for updating event periods
   *
   * @param eventPeriods event periods
   */
  private onUpdateEventPeriods = (eventPeriods: EventPeriod[]) => {
    this.setState({ eventPeriods: eventPeriods });
  }

  /**
   * Event handler for submit click event
   */
  private onSubmitClick = async () => {
    const { mode, event, formValid } = this.state;

    event.externalLinks = EventUtils.preprocessExternalLinks(event);

    if (!formValid) {
      this.setState({
        showValidationMessages: true
      });

      this.setSnackbarMessage({
        message: strings.validator.invalidFieldsWereFound,
        severity: "warning"
      });
      return;
    }

    if (mode === "edit") {
      await this.editEvent(event);
    } else {
      await this.addEvent(event);
    }
  }

    /**
   * Method for updating event's division
   *
   * @param value string
   */
  private onDivisionUpdate = (value: string) => {
    const division = value;
    this.setState({ division: division });
  }

  /**
   * Method for updating event's place
   *
   * @param place new place
   */
  private onPlaceUpdate = (place: Place | null) => {
    if (!place || !place.id) {
      return;
    }

    this.setState({ eventLocation: place });
  }

  /**
   * Method for fetching places
   *
   * @param page page number, defaults to one
   */
  private fetchPlaces = async (page: number = 1) => {
    const { accessToken } = this.props;
    const { places } = this.state;

    const filterApi = Api.getFilterApi(accessToken);
    const response = await filterApi.placeList({
      pageSize: 100,
      page: page,
      showAllPlaces: true
    });

    if (!response.data) {
      return;
    }

    const filteredPlaces = response.data.filter(place => {
      return !place.deleted && (!place.customData || place.customData.listed === "true");
    });

    this.setState({
      places: [
        ...places,
        ...filteredPlaces
      ]
    });

    if (response.meta && response.meta.next) {
      this.fetchPlaces(++page);
    } else {
      this.setState({ placesLoaded: true });
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  locale: state.locale.locale,
  keycloak: state.auth.keycloak,
  accessToken: state.auth.accessToken
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(CreateOrUpdateEventScreen)
const CustomStyled = withCustomStyles("screens/create-or-update-event-screen")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
