import * as React from 'react';
import { CustomStyles } from "../../types";

/**
 * Interface describing injected properties
 */
interface InjectedProps {
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State {
  customStyles?: CustomStyles;
}

/**
 * Higher-order component for adding custom styles
 *
 * @param stylesPath custom styles path
 * @param Component React component to inject with custom styles
 */
export const withCustomStyles = (stylesPath: string) =>
  <Props extends InjectedProps>(Component: React.ComponentType<Props>) =>
    class WithCustomStyles extends React.Component<Props, State> {

      /**
       * Component constructor
       * 
       * @param props props
       */
      constructor(props: Props) {
        super(props);
        this.state = {};
      }

      /**
       * Component did mount life cycle method
       */
      public async UNSAFE_componentWillMount() {
        import(`../../dynamic-content/custom-styles/${stylesPath}`)
          .then(module => this.setState({ customStyles: module.default }))
          .catch(e => console.log("error: ", e));
      }

      /**
       * Component render method
       */
      public render() {
        return (
          <Component
            { ...this.props }
            customStyles={ this.state.customStyles }
          />
        );
      }
    }
