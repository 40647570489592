import * as React from "react";
import { CustomStyles } from "../../types";
import { History } from "history";
import { Event } from "../../generated/client/models/Event";
import { Grid, WithStyles, withStyles } from "@material-ui/core";
import moment from "moment";
import styles from "../../styles/generic/google-marker-event";
import strings from "../../localization/strings";
import { EventName } from "../../generated/client";
import EventUtils from "../../utils/event-utils";
import { withCustomStyles } from "../hocs/with-custom-styles";

/**
 * Component props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?:CustomStyles;
  event: Event;
  history: History<History.LocationState>;
}

/**
 * Creates event item for google marker popper
 * @param props
 */
const GoogleMarkerEventItem = (props: Props) => {
  const { event, classes, customStyles } = props;
  const name = event.name[strings.getLanguage() as keyof EventName] || event.name.fi;

  /**
   * Handles click event for event item. Opens event info to new tab
   */
  const onEventClick = () => {
    const { history } = props;
    const url = `${ event.id }`;
    const id = EventUtils.parseIdFromUrl(url);
    history.push(`/event/${ id }`);
  };

  return (
    <Grid container item 
      className={ classes.eventContainer } 
      style={ customStyles?.eventContainer }
      onClick={ onEventClick }
    >
      <Grid item 
        xs={ 2 } 
        className={ classes.imageContainer } 
        style={{ backgroundImage: `url(${ event.images && event.images.length && event.images[0].url })`, ...customStyles?.imageContainer}}
      >
      </Grid>
      <Grid item 
        xs={ 10 } 
        className={ classes.infoContainer } 
        style={ customStyles?.infoContainer }
      >
        <h4 
          className={ classes.name } 
          style={ customStyles?.name } 
        >
          { name }
        </h4>
        <h5 
          className={ classes.time } 
          style={ customStyles?.time }
        >
          { buildTimeString(event) }
        </h5>
      </Grid>
    </Grid>
  );
};

/**
 * Checks if any time in hours:minutes format is 00.00
 * @param time
 */
const checkTime = (time: Date | undefined): boolean => {
  return moment(time).format("LT") === "00.00";
};

/**
 * Builds time string
 * @param event
 */
export const buildTimeString = (event: Event) => {
  const { startTime, endTime } = event;

  let time = moment(startTime).format("L");

  const isSameDay: boolean = moment(startTime).isSame(moment(endTime), "date");

  if (!checkTime(startTime)) {
    time += ` ${moment(startTime).format("LT")}`;
  }
  if (!isSameDay) {
    time += ` - `;

    if (endTime) {
      time += ` ${moment(endTime).format("L")}`;
    }

    if (!checkTime(endTime)) {
      time += ` ${moment(endTime).format("LT")}`;
    }
  }

  return time;
};

export default withCustomStyles("generic/google-marker-event-item")(withStyles(styles, { withTheme: true })(GoogleMarkerEventItem));