import { Typography, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { Event } from "../../generated/client";
import styles from "../../styles/event/event-banner";
import { CustomStyles } from "../../types";
import EventUtils from "../../utils/event-utils";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { Helmet } from "react-helmet";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  event: Event;
  customStyles?: CustomStyles;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Component for event banner
 * 
 * @param props component props
 */
class EventBanner extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, event } = this.props;

    const name = EventUtils.getEventName(event);
    const imageSrc = EventUtils.getEventImageSrc(event);

    return (
      <div
        className={ classes.container }
        style={{
          ...customStyles?.eventImage,
          backgroundImage: `url(${ imageSrc })`
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="title" content={ name } />
          <meta property="og:title" content={ name } />
          <meta property="og:image" content={ imageSrc } />
        </Helmet>
        <Typography
          variant={ "h1" }
          className={ classes.eventTitle }
          style={ customStyles?.eventTitle }
        >
          { name }
        </Typography>
      </div>
    );
  }

}

const Styled = withStyles(styles)(EventBanner);
const CustomStyled = withCustomStyles("event/event-banner")(Styled);

export default CustomStyled;