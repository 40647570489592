import * as React from "react";
import { Grid, Button, Popper, Paper, WithStyles, ClickAwayListener, Fade } from "@material-ui/core";
import SearchListItemGeneric from "./search-list-item-generic";
import { Keyword } from "../../generated/client/models/Keyword";
import styles from "../../styles/generic/search-dropdown-generic-styles";
import { withStyles } from "@material-ui/styles";
import { CustomStyles } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";

/**
 * Component Props
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  label: string;
  disabled: boolean;
  keywords: Keyword[];
  selectedIds: string[];
  isTypeWhom?: boolean;
  onFilterListChange: (keywordId: string, selected: boolean) => void;
}

/**
 * Creates component for changing search parameters for filtter
 * @param props
 */
const SearchDropDownGeneric: React.FC<Props> = ({ 
    customStyles, 
    classes, 
    onFilterListChange, 
    keywords,
    selectedIds,
    label: title, 
    buttonTextColor, 
    buttonBackgroundColor, 
    isTypeWhom,
    disabled
  }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | undefined | HTMLElement>(null);
  const gridRef = React.useRef();

  /**
   * Handles click event
   */
  const handleClick = () => {
    setAnchorEl(anchorEl ? null : gridRef.current);
  };

  /**
   * Handles click away event for popper closing it
   */
  const onKeywordListClickAway = () => {
    setAnchorEl(null);
  };

  const open: boolean = Boolean(anchorEl);
  const selected = keywords.filter(keyword => selectedIds.indexOf(keyword.id ? keyword.id : "") !== -1);
  const selecteable = keywords.filter(keyword => selectedIds.indexOf(keyword.id ? keyword.id : "") === -1);

  return (
    <div 
      className={ classes.searchParamContainer }
      style={ customStyles?.searchParamContainer }
    >
      <Grid 
        className={ classes.dropdownContainer }
        style={ customStyles?.dropdownContainer }
        container
        item
        justify="center"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className={ classes.buttonContainer }
          style={ customStyles?.buttonContainer }
          innerRef={ gridRef }
        >
          <Button
            style={{ color: buttonTextColor, backgroundColor: buttonBackgroundColor }}
            disableElevation
            variant="contained"
            color="inherit"
            onClick={ handleClick }
            disabled={ disabled }
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          >
            { title }
          </Button>
        </Grid>
      </Grid>

      <Popper open={ open } placement="bottom" anchorEl={ anchorEl } transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={ onKeywordListClickAway }>
            <Fade { ...TransitionProps }>
              <Paper 
                className={ classes.itemList }
                style={ customStyles?.itemList }
              >
                { selecteable.map(keyword => {
                  return <SearchListItemGeneric isTypeWhom={ isTypeWhom } key={ keyword.id } keyword={ keyword } onChange={ onFilterListChange } selected={ false } />;
                }) }
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default withCustomStyles("generic/search-dropdown-generic")(withStyles(styles, { withTheme: true })(SearchDropDownGeneric));
