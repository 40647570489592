import * as React from "react";

import { Button, Typography, withStyles, WithStyles } from "@material-ui/core";
import { withCustomStyles } from "../hocs/with-custom-styles";
import styles from "../../styles/generic/footer";
import strings from "../../localization/strings";
import ContactInfo from "./contact-info";
import SocialMedia from "./social-media";
import { CustomStyles } from "../../types";
import { Link } from "react-router-dom";

import footerLogo from  "../../resources/svg/metatavu.svg";

/**
 * Interface describing optional props
 */
export interface OptionalProps {
}

/**
 * Interface describing component properties
 */
export interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
}

/**
 * Functional component for app footer
 * 
 * @param props component props
 */
const Footer: React.FC<Props & OptionalProps> = ({
  classes,
  customStyles
}) => {
  const [ logo, setLogo ] = React.useState<string | undefined>();

  /**
   * Imports logo after component mounts
   */
  React.useEffect(() => {
    const logoPath = "resources/svg/metatavu.svg";
    import(`../../dynamic-content/${logoPath}`)
      .then(logo => setLogo(logo.default))
      .catch(() => {});
    }, []);

  /**
   * Component render
   */
  return (
    <div
      className={ classes.container }
      style={ customStyles?.container }
    >
      <div
        className={ classes.content }
        style={ customStyles?.content }
      >
        <div 
          className={ classes.innerContent }
          style={ customStyles?.innerContent }
        >
          <div 
            className={ classes.contactContainer }
            style={ customStyles?.contactContainer }
          >
            <div 
              className={ classes.logo }
              style={ customStyles?.logo }
            >
              <img alt={ strings.client.footerLogoAtlText } src={ footerLogo } />
            </div>
            <ContactInfo />
          </div>
          <div 
            className={ classes.socialContainer }
            style={ customStyles?.socialContainer }
          >
            <SocialMedia />
            <Button
              target="_blank" 
              href={ strings.footer.feedbackLink }
              variant="contained"
              color="secondary"
              className={ classes.mailingListButton }
              style={ customStyles?.mailingListButton }
            >
              { strings.footer.feedbackLinkButtonText }
            </Button>
          </div>
        </div>
      </div>
      <div
        className={ classes.additionalInfo }
        style={ customStyles?.additionalInfo }
      >
        <div 
          className={ classes.additionalInfoContent }
          style={ customStyles?.additionalInfoContent }
        >
          <Typography 
            className={ classes.copyright }
            style={ customStyles?.copyright }
            variant="body2"
          >
            { "\u00A9" } { strings.client.copyrightText }
          </Typography>
          <Link 
            to= "/privacy_policy"
            className={ classes.link }
            style={ customStyles?.link }
          >
            { strings.footer.privacyPolicy }
          </Link>
          <Link
            to= "/accessibility"
            className={ classes.link }
            style={ customStyles?.link }
          >
            { strings.footer.accessibilityDescription }
          </Link>
          <Link
            to= "/publisher_help"
            className={ classes.link }
            style={ customStyles?.link }
          >
            { strings.footer.publisherHelp }
          </Link>
        </div>
      </div>
    </div>
  );
}

const Styled = withStyles(styles)(Footer);
const CustomStyled = withCustomStyles("generic/footer")(Styled);

export default CustomStyled;