import * as React from 'react';

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../store";

import Api from "../../api/api";
import { withStyles, WithStyles, Typography } from "@material-ui/core";
import AppLayout from "../layouts/app-layout";
import styles from "../../styles/screens/home-screen";
import { History } from "history";
import { NullableToken, CustomStyles, FiltersParam, OpenPage } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
import strings from '../../localization/strings';
import ReactHtmlParser from "react-html-parser";
import EventSearchForm from "../event/event-search-form";
import EventLister from "../event/event-lister";
import { Event, EventListRequest } from "../../generated/client";
import moment from 'moment';
import AllEventsMap from "../generic/all-events-map"

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  history: History<History.LocationState>;
}

/**
 * Component state
 */
interface State {
  loading: boolean;
  pageOpen: OpenPage;
  filters: FiltersParam;
  longTermEvents: Event[];
  shortTermEvents: Event[];
  longTermEventsPage: number;
  shortTermEventsPage: number;
  loadMoreLongDisabled: boolean;
  loadMoreShortDisabled: boolean;
  loadingLongTermEvents: boolean;
  loadingShortTermEvents: boolean;
}

/**
 * Home screen component 
 */
class HomeScreen extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      longTermEvents: [],
      shortTermEvents: [],
      pageOpen: "eventlist",
      longTermEventsPage: 1,
      shortTermEventsPage: 1,
      loadMoreLongDisabled: false,
      loadMoreShortDisabled: false,
      loadingLongTermEvents: false,
      loadingShortTermEvents: false,
      filters: this.initializeFilters()
    }
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = async () =>  {
    this.setState({
      loading: true,
      loadingLongTermEvents: true,
      loadingShortTermEvents: true
    });

    const eventData = await this.fetchEventData();

    if (!eventData) {
      return;
    }

    this.setState({
      loading: false,
      loadingLongTermEvents: false,
      loadingShortTermEvents: false,
      longTermEvents: eventData.longEvents,
      shortTermEvents: eventData?.shortEvents,
      loadMoreLongDisabled: eventData.longMetaData && eventData.longMetaData.next === undefined ? true : false,
      loadMoreShortDisabled: eventData.shortMetaData && eventData.shortMetaData.next === undefined ? true : false
    })
  }

  /**
   * ComponentDidUpdate
   *
   * @param prevProps
   * @param prevState
   */
  public componentDidUpdate = async (prevProps: Props, prevState: State) => {

      if (prevState.filters !== this.state.filters) {
      this.setState({ loading: true });

      const eventData = await this.fetchEventData();

      if(!eventData) {
        return;
      }
      
      this.setState({
        shortTermEvents: eventData.shortEvents,
        longTermEvents: eventData.longEvents,
        loadMoreLongDisabled: eventData.longMetaData && eventData.longMetaData.next === undefined ? true : false,
        loadMoreShortDisabled: eventData.shortMetaData && eventData.shortMetaData.next === undefined ? true : false,
        loading: false
      });
    }
  };


  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, history } = this.props;
    const {
      filters,
      pageOpen,
      longTermEvents,
      shortTermEvents,
      loadMoreLongDisabled,
      loadMoreShortDisabled,
      loadingLongTermEvents,
      loadingShortTermEvents
    } = this.state;

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container}
        >
          <Typography
            variant={ "h1" }
            className={ classes.heading }
            style={ customStyles?.heading }
          >
            { this.renderHeading() }
          </Typography>
          <div 
            className={ classes.content }
            style={ customStyles?.content }
          >
            <EventSearchForm
              onFiltersChange={ this.onFiltersChange }
              filters={ filters }
              onListButtonClick={ this.onListButtonClick }
              onMapButtonClick={ this.onMapButtonClick }
              pageOpen={ pageOpen }
            />
            { pageOpen === "eventlist" &&
            <div>
              <EventLister
                history={ history }
                events={ longTermEvents }
                loading={ loadingLongTermEvents }
                disabled={ loadMoreLongDisabled }
                title={ strings.event.longTermEvents }
                loadMoreEvents={ this.onLoadMoreLongEventsClick }
                loadMoreButtonText={ strings.event.loadMoreLongTermEvents }
                showEditButtons={ false }
              />
              <EventLister
                history={ history }
                events={ shortTermEvents }
                title={ strings.event.allEvents }
                disabled={ loadMoreShortDisabled }
                loading={ loadingShortTermEvents }
                loadMoreEvents={ this.onLoadMoreShortEventsClick }
                loadMoreButtonText={ strings.event.loadMoreEvents }
                showEditButtons={ false }
              />
            </div>
            }
            { pageOpen === "googlemaps" && 
              <AllEventsMap
                history={ history }
                filters={ filters }
              /> 
            }
          </div>
        </div>
      </AppLayout>
    )
  }

  /**
   * Initializes filter object
   *
   * @returns FiltersParam object
   */
  private initializeFilters = (): FiltersParam => ({
    selectedAudienceIds: [],
    selectedCategoryIds: [],
    selectedPlaceIds: [],
    dateStart: moment().toDate(),
    dateEnd: undefined,
    page: 1,
    pageSize: 12,
    text: undefined
  });

  /**
   * Method for fetching short term events
   */
  private fetchEventData = async () => {
    try {
      const { accessToken } = this.props;

      const eventsApi = Api.getEventApi(accessToken);
      const apiDataShort = await eventsApi.eventList({ ...this.createShortTermFilterParams() });
      const apiDataLong = await eventsApi.eventList({ ...this.createLongTermFilterParams() });

      if (!apiDataShort || !apiDataLong) {
        return;
      }

      const shortEvents = apiDataShort.data || [];
      const shortMetaData = apiDataShort.meta;
      const longEvents = apiDataLong.data || [];
      const longMetaData = apiDataLong.meta;

      return { shortEvents, shortMetaData, longEvents, longMetaData };

    } catch (error) {
      console.log(error);
    }
  }


  /**
   * Creates and returns filterParams object from filter for short term events object in state
   *
   * @returns filterParams object
   */
  private createShortTermFilterParams = (): EventListRequest => {
    const { filters } = this.state;

    const filterParams: EventListRequest = {
      keyword: [...filters.selectedAudienceIds, ...filters.selectedCategoryIds].join(","),
      start: filters.dateStart,
      end: filters.dateEnd,
      addressLocalityFi: [...filters.selectedPlaceIds].join(","),
      page: 1,
      pageSize: filters.pageSize,
      sort: "start_time",
      text: filters.text,
      minDuration: undefined,
      maxDuration: 86400
    };

    return filterParams;
  };

  /**
   * Creates and returns filterParams object from filter for long term events object in state
   *
   * @returns filterParams object
   */
  private createLongTermFilterParams = (): EventListRequest => {
    const { filters } = this.state;

    const filterParams: EventListRequest = {
      keyword: [...filters.selectedAudienceIds, ...filters.selectedCategoryIds].join(","),
      start: filters.dateStart,
      end: filters.dateEnd,
      addressLocalityFi: [...filters.selectedPlaceIds].join(","),
      page: 1,
      pageSize: filters.pageSize,
      sort: "start_time",
      text: filters.text,
      minDuration: 86400,
      maxDuration: undefined
    };

    return filterParams;
  };

  /**
   * Loads more short term event content by changing page count
   *
   * @returns events, metaData
   */
  private loadMoreShortTermEvents = async () => {
    const { longTermEventsPage: shortTermEventsPage } = this.state;
    const  { accessToken } = this.props;

    this.setState({
      loading: true
    })

    const eventApi = Api.getEventApi(accessToken);

    const apiData = await eventApi.eventList({ ...this.createShortTermFilterParams(), page: shortTermEventsPage + 1, sort: "start_time" });

    if (!apiData) {
      return;
    }

    const events = apiData.data || [];
    const metaData = apiData.meta;

    return {
      events,
      metaData
    }
  };

    /**
   * Loads more long term event content by changing page count
   *
   * @returns events, metaData
   */
  private loadMoreLongTermEvents = async () => {
    const { longTermEventsPage } = this.state;
    const  { accessToken } = this.props;

    this.setState({
      loading: true
    })

    const eventApi = Api.getEventApi(accessToken);

    const apiData = await eventApi.eventList({ ...this.createLongTermFilterParams(), page: longTermEventsPage + 1, sort: "start_time" });

    if (!apiData) {
      return;
    }

    const events = apiData.data || [];
    const metaData = apiData.meta;

    return {
      events,
      metaData
    }
  };


  /**
   * Handles onClick event for button
   */
  private onLoadMoreShortEventsClick = async () => {
    const { shortTermEvents, shortTermEventsPage } = this.state;

    this.setState({
      loading: true,
      loadingShortTermEvents: true
    });

    const apiData = await this.loadMoreShortTermEvents();

    if (!apiData) {
      return;
    }

    this.setState({
      loading: false,
      loadingShortTermEvents: false,
      loadMoreShortDisabled: apiData.metaData && apiData.metaData.next === undefined ? true : false,
      shortTermEvents: [...shortTermEvents].concat(apiData.events),
      shortTermEventsPage: shortTermEventsPage + 1
    });
  };

  /**
   * Handles onClick event for button
   */
  private onLoadMoreLongEventsClick = async () => {
    const { longTermEvents, longTermEventsPage } = this.state;

    this.setState({
      loading: true,
      loadingLongTermEvents: true
    });

    const apiData = await this.loadMoreLongTermEvents();

    if (!apiData) {
      return;
    }

    this.setState({
      loading: false,
      loadingLongTermEvents: false,
      loadMoreLongDisabled: apiData.metaData && apiData.metaData.next === undefined ? true : false,
      longTermEventsPage: longTermEventsPage + 1,
      longTermEvents: [...longTermEvents].concat(apiData.events)
    });
  };

  /**
   * Method for rendering heading
   */
  private renderHeading = () => {
    const formattedName = `<b>${ strings.client.toClientName }!</b>`;
    const formattedHeader = strings.formatString(strings.homeScreen.heading, formattedName);
    return ReactHtmlParser(formattedHeader as string);
  }

  /**
   * Shows content in List view
   */
  private onListButtonClick = () => {
    this.setState({
      pageOpen: "eventlist"
    });
  };

  /**
   * Shows content in Map view
   */
  private onMapButtonClick = () => {
    this.setState({
      pageOpen: "googlemaps"
    });
  };


  /**
   * Resets filtters
   */
  private onResetFilttersClick = () => {
    this.setState({
      filters: this.initializeFilters()
    });
  };

  /**
   * Sets state new filter changes
   */
  private onFiltersChange = (filters: FiltersParam) => {
    this.setState({
      filters: filters
    });
  };

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(HomeScreen)
const CustomStyled = withCustomStyles("screens/home-screen")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
