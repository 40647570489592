import * as React from 'react';

import { Dispatch } from "redux";
import { Button, withStyles, WithStyles } from "@material-ui/core";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { CustomStyles } from "../../types";
import styles from "../../styles/screens/info-screen";
import AppLayout from "../layouts/app-layout";
import { connect } from "react-redux";
import strings from "../../localization/strings";
import parseHTML from 'html-react-parser';
import { ReduxState, ReduxActions } from '../../store';

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  infoContentKey: keyof typeof strings.infoScreen;
  locale: string;
}

/**
 * Component state
 */
interface State {
}

/**
 * Info screen component 
 */
class InfoScreen extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component did mount life-cycle handler
   */
  public componentDidMount = () =>  {
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, infoContentKey } = this.props;
    const infoContent = strings.infoScreen[infoContentKey];

    return (
      <AppLayout>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          { parseHTML(infoContent) }
        </div>
        <Button href="/">
          {strings.infoScreen.returnToFrontpage}
        </Button>
      </AppLayout>
    )
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({ });

const Styled = withStyles(styles)(InfoScreen);
const CustomStyled = withCustomStyles("screens/info-screen")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
