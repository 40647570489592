import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { Config } from "./constants/configuration";

const config = Config.getConfig();

Sentry.init({
  dsn: config.sentryDSN,
  integrations: [ new Integrations.BrowserTracing() ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
