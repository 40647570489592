import * as React from 'react';

import styles from "../../styles/generic/localized-input";
import { withCustomStyles } from '../hocs/with-custom-styles';
import { CustomStyles, Localizations, LocalizedStrings } from '../../types';
import { Checkbox, FormControlLabel, withStyles, WithStyles } from '@material-ui/core';
import EventUtils from '../../utils/event-utils';

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  localizations: Localizations;
  localizedStrings: LocalizedStrings;
  onChange: (locale: string) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Localization checkboxes component
 */
class LocalizationCheckboxes extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderCheckboxes() }
      </div>
    );
  }

  /**
   * Method for rendering checkboxes
   */
  private renderCheckboxes = () => {
    const { localizations, onChange, localizedStrings } = this.props;
    const localizationKeys = EventUtils.getLocalizationKeys();

    return localizationKeys.map((key: string, index: number) => {

      const localizedString = localizedStrings[key as keyof object];

      return (
        <FormControlLabel
          key={ index }
          label={ localizedString }
          control={
            <Checkbox
              checked={ localizations[key as keyof object] }
              onChange={ onChange(key) } 
            />
          }
        />
      );
    });
  }

}

const Styled = withStyles(styles)(LocalizationCheckboxes);
const CustomStyled = withCustomStyles("generic/localized-checkboxes")(Styled);

export default CustomStyled;