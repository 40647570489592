import * as React from "react";

import { CustomStyles } from "../../types";
import ImagePicker from "react-image-picker";
import { IMAGES } from "../../constants/default-images";
import styles from "../../styles/generic/default-images";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { withCustomStyles } from "../hocs/with-custom-styles";
import { ImageCreateRequest } from "../../generated/client";
import theme from "../../dynamic-content/theme";
import strings from "../../localization/strings";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  customStyles?: CustomStyles;
  onPick: (requestParams: ImageCreateRequest) => void;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Interface describing image picker option
 */
interface ImagePickerOption {
  src: string;
  value: number;
}

/**
 * Default images component
 */
class DefaultImages extends React.Component<Props, State> {

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles } = this.props;

    return (
      <>
        <Typography variant="body1" style={{ marginTop: theme.spacing(2) }}>{ strings.eventForm.pickExistingImage }</Typography>
        <div
          className={ classes.container }
          style={ customStyles?.container }
        >
          <ImagePicker
            onPick={ this.pickImage }
            images={ this.mapOptions() }
          />
        </div>
      </>
    );
  }

  /**
   * Method for mapping image options
   *
   * @returns array of image picker options
   */
  private mapOptions = (): ImagePickerOption[] => {
    return IMAGES.map((image, index) => {
      return {
        value: index,
        src: image.url
      };
    });
  }

  /**
   * Method for picking image
   *
   * @param option image picker option
   */
  private pickImage = (option: ImagePickerOption) => {
    this.props.onPick(IMAGES[option.value]);
  }

}

const Styled = withStyles(styles)(DefaultImages);
const CustomStyled = withCustomStyles("generic/default-images")(Styled);

export default CustomStyled;