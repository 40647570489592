import { Box, Button, Card, CardActions, CardMedia, withStyles, WithStyles, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@material-ui/core';
import RemoveIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import "cropperjs/dist/cropper.css";
import * as React from "react";
import Cropper from "react-cropper";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import SimpleReactValidator from "simple-react-validator";
import Api from "../../api/api";
import { Image as EventImage, ImageCreateRequest } from "../../generated/client";
import strings from "../../localization/strings";
import { ReduxActions, ReduxState } from "../../store";
import styles from "../../styles/generic/event-image-picker";
import { CustomStyles, NullableToken, PostData, PresignedPostData } from "../../types";
import DefaultImages from "../generic/default-images";
import { withCustomStyles } from "../hocs/with-custom-styles";

/**
 * Interface describing component properties
 */
interface Props extends WithStyles<typeof styles> {
  image?: EventImage;
  previewImage?: EventImage;
  showMessages: boolean;
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  validator: SimpleReactValidator;
  onRemoveImage: () => void;
  onChange: (image: EventImage) => void;
  onSetPreviewImage: (name: string, url: string) => void;
}

/**
 * Interface describing component state
 */
interface State {
  error?: string;
  loading: boolean;
  cropping: boolean;
  croppedCanvas?: HTMLCanvasElement;
}

/**
 * Form item component
 */
class EventImagePicker extends React.Component<Props, State> {

  /**
   * Cropper reference
   */
  private cropper?: Cropper;

  /**
   * Used for revoking previous preview url
   */
  private previewImageUrl?: string;

  /**
   * Constructor
   * 
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      cropping: false
    };
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes, customStyles, image } = this.props;
    const { cropping } = this.state;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        { this.renderValidatorMessage("image", image) }
        { this.renderPreview() }
        { this.renderDropzone() }
        <Dialog open={ cropping }>
          <DialogTitle>
            <Typography variant="h3">{ strings.eventForm.cropTitle }</Typography>
            <Typography>{ strings.eventForm.cropInstructions }</Typography>
          </DialogTitle>
          { this.renderCropper() }
        </Dialog>
        <DefaultImages onPick={ this.onPick } />
      </div>
    );
  }

  /**
   * Method for rendering image name
   */
  private renderPreview = () => {
    const { customStyles, classes, image } = this.props;

    if (!image) {
      return null;
    }

    const imageUrl = image.url;
    const imageName = image.name;

    return (
      <Card 
        className={ classes.previewContainer }
        style={ customStyles?.previewContainer }
      >
        <CardMedia
          image={ imageUrl }
          title={ imageName }
          className={ classes.preview }
          style={ customStyles?.preview }
        />
        <CardActions disableSpacing>
          <Box display="flex" flex={ 1 } justifyContent="flex-end" >
            <Button startIcon={ <RemoveIcon /> } variant="text" onClick={ this.deleteImage }>
              { strings.eventForm.removeImage }
            </Button>
          </Box>
        </CardActions>
      </Card>
    );
  }

  /**
   * Method for rendering dropzone
   */
  private renderDropzone = () => {
    return (
      <Dropzone
        maxSize={ 2000000 }
        accept={[".jpeg", ".jpg", ".png"]}
      >
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} type="file" onChange={ this.setPreviewImage } />
              <Button>
                { strings.eventForm.chooseImage }
              </Button>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }

  /**
   * Method for rendering cropper
   */
  private renderCropper = () => {
    const { previewImage } = this.props;
    const { cropping, loading, error } = this.state;

    if (!previewImage || !cropping) {
      return null;
    }

    const imageUrl = previewImage.url;

    return (
      <React.Fragment>
        <DialogContent>
          <Cropper
            src={ imageUrl }
            crop={ this.onCrop }
            onInitialized={ this.initCropper }
            aspectRatio={ 57 / 20 }
            guides={ false }
            zoomable={ false }
          />
        </DialogContent>
        <DialogActions>
          { this.renderAlert() }
          <Button
            startIcon={ loading ? <CircularProgress size={ 20 } color="primary" /> : "" }
            disabled={ !!error }
            onClick={ this.submitImage }
          >
            { loading ? strings.eventForm.loadingImage : strings.eventForm.crop }
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  /**
   * Method for rendering validator message
   *
   * @param field field
   * @param value value
   */
  private renderValidatorMessage = (field: string, value?: EventImage) => {
    const { validator } = this.props;

    return validator.message(field, value, "required");
  }

  /**
   * Method for rendering alert
   */
  private renderAlert = () => {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <Alert severity="error">{ error }</Alert>
    );
  }

  /**
   * Method for initializing cropper
   *
   * @param instance cropper instance
   */
  private initCropper = (instance: Cropper) => {
    this.cropper = instance;
  }

  /**
   * Method for cropping image
   *
   * @param event event object
   */
  private onCrop = async (event: Cropper.CropEvent) => {

    if (!this.cropper) {
      return;
    }

    const croppedCanvas = this.cropper.getCroppedCanvas();

    const valid = await this.validateImageSize(croppedCanvas.toDataURL());
    const error = valid ? "" : strings.errors.cropIsTooSmall;

    this.setState({
      error: error,
      croppedCanvas: croppedCanvas
    });
  }

  /**
   * Method for setting preview image
   *
   * @param event event object
   */
  private setPreviewImage = async (event: React.ChangeEvent<any>) => {
    const { onSetPreviewImage } = this.props;

    if (this.previewImageUrl) {
      URL.revokeObjectURL(this.previewImageUrl);
      this.previewImageUrl = undefined;
    }

    const files: FileList = event.target.files;

    if (!files.length) {
      return;
    }

    const imageFile = files[0];
    const imageName = imageFile.name;
    const imageUrl = URL.createObjectURL(imageFile);
    this.previewImageUrl = imageUrl;

    const valid = await this.validateImageSize(imageUrl);

    if (!valid) {
      this.setState({
        error: strings.errors.imageIsTooSmall
      });
    } else {
      onSetPreviewImage(imageName, imageUrl);
      this.setState({ error: "", cropping: true });
    }
  }

  /**
   * Method for validating image size
   *
   * @param url image url
   * @returns boolean
   */
  private validateImageSize = (url: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        resolve(image.width >= 1140 && image.height >= 400)
      }
      image.src = url;
    });
  }

  /**
   * Method for deleting image
   */
  private deleteImage = () => {
    this.props.onRemoveImage();
  }

  /**
   * Method for creating linked events image
   *
   * @param file file
   * @returns linked events image
   */
  private createImage = async (file: Blob): Promise<EventImage> => {
    const { accessToken, previewImage } = this.props;

    if (!previewImage || !previewImage.name) {
      return Promise.reject();
    }

    const presignedPostData = await this.getPresignedPostData(file);
    await this.uploadImage(file, presignedPostData.data);

    const imageUrl = encodeURI(`${presignedPostData.basePath}/${presignedPostData.data.fields.key}`);
    const imageApi = Api.getImageApi(accessToken);
    const image = await imageApi.imageCreate({
      name: previewImage.name,
      url: imageUrl
    });

    return image;
  }

  /**
   * Uploads image to s3 bucket
   *
   * @param file file
   * @returns Promise
   */
  private uploadImage = async (file: Blob, postData: PostData): Promise<void> => {
    const formData = new FormData();
    formData.append("file", file);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(postData.fields).forEach(key => {
        formData.append(key, postData.fields[key]);
      });

      formData.append("file", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", postData.url, true);
      xhr.send(formData);
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText);
      };
    });
  }

  /**
   * Retrieve pre-signed POST data from a dedicated API endpoint.
   * @param file file
   * @returns presigned post data
   */
  private getPresignedPostData = (file: Blob): Promise<PresignedPostData> => {
    const { previewImage } = this.props;

    if (!previewImage || !previewImage.name) {
      return Promise.reject();
    }

    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      const bucketUrl = process.env.REACT_APP_USER_CONTENT_UPLOAD_URL || "";
      
      xhr.open("POST", bucketUrl, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(
        JSON.stringify({
          userId: "event-images",
          name: previewImage.name,
          type: file.type
        })
      );
      xhr.onload = function() {
        resolve(JSON.parse(this.responseText));
      };
    });
  }

  /**
   * Method for submitting image
   *
   * @param event event
   */
  private submitImage = async (event: React.MouseEvent<any>) => {
    event.preventDefault();
    const { onChange } = this.props;
    const { croppedCanvas } = this.state;

    if (!croppedCanvas) {
      return;
    }

    const valid = await this.validateImageSize(croppedCanvas.toDataURL());

    if (!valid) {
      return;
    }

    this.setState({
      loading: true
    });

    const file = await this.imageFileFromCanvas(croppedCanvas);
    const image = await this.createImage(file);
    onChange(image);

    this.setState({
      error: "",
      loading: false,
      cropping: false,
      croppedCanvas: undefined
    });
  }

  /**
   * Method for converting canvas to image file
   *
   * @param canvas canvas
   * @returns blob
   */
  private imageFileFromCanvas = (canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => blob ? resolve(blob) : reject());
    });
  }

  /**
   * Method for picking image
   *
   * @param requestParams create image request params
   */
  private onPick = async (requestParams: ImageCreateRequest) => {
    const { accessToken, onChange } = this.props;

    const imageApi = Api.getImageApi(accessToken);
    const image = await imageApi.imageCreate(requestParams);

    onChange(image);
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(EventImagePicker);
const CustomStyled = withCustomStyles("generic/event-image-picker")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
