import { createStyles } from "@material-ui/core";
import theme from "../../dynamic-content/theme";

export default createStyles({

  container: {
    height: 300,
    width: "100%",
    background: theme.palette.background.default
  },

  input: {
    width: "100%",
    padding: theme.spacing(2)
  }

});