import * as React from "react";
import { Grid, Button, Popper, Paper, WithStyles, ClickAwayListener, Fade } from "@material-ui/core";
import SearchListItemPlace from "./search-list-item-place";
import styles from "../../styles/generic/search-dropdown-places";
import { withStyles } from "@material-ui/styles";
import { PlaceByLocality, CustomStyles } from "../../types";
import { withCustomStyles } from "../hocs/with-custom-styles";
/**
 * Component Props
 */
interface Props extends WithStyles<typeof styles> {
  buttonBackgroundColor: string;
  buttonTextColor: string;
  label: string;
  customStyles?: CustomStyles;
  disabled: boolean;
  places: PlaceByLocality[];
  selectedIds: string[];
  onFilterListChange: (keywordId: string, selected: boolean) => void;
}

/**
 * Creates component for changing search parameters for filtter
 *
 * @param props Component props
 */
const SearchDropDownPlaces: React.FC<Props> = ({ 
    customStyles, 
    classes, 
    onFilterListChange, 
    label, 
    buttonTextColor, 
    buttonBackgroundColor,
    places,
    selectedIds,
    disabled
  }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | undefined | HTMLElement>(null);
  const gridRef = React.useRef();

  /**
   * Handles click event
   */
  const handleClick = () => {
    setAnchorEl(anchorEl ? null : gridRef.current);
  };

  /**
   * Handles click away event for popper closing it
   */
  const onKeywordListClickAway = () => {
    setAnchorEl(null);
  };

  const open: boolean = Boolean(anchorEl);
  const selected = places.filter((item: PlaceByLocality) => selectedIds.indexOf(item.address_locality_fi) !== -1);
  const selecteable = places.filter(item => selectedIds.indexOf(item.address_locality_fi) === -1);

  return (
    <div
      className={ classes.searchParamContainer }
      style={ customStyles?.searchParamContainer }
    >
      <Grid
        className={ classes.dropdownContainer }
        style={ customStyles?.dropdownContainer }
        container
        item
        justify="center"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          container
          alignItems="stretch"
          justify="center"
          className={ classes.buttonContainer }
          style={ customStyles?.buttonContainer }
          innerRef={ gridRef }
        >
          <Button
            style={{ color: buttonTextColor, backgroundColor: buttonBackgroundColor }}
            disableElevation
            variant="contained"
            color="inherit"
            onClick={ handleClick }
            disabled={ disabled }
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          >
            { label }
          </Button>
        </Grid>
      </Grid>

      <Popper open={ open } placement="bottom" anchorEl={ anchorEl } transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={ onKeywordListClickAway }>
            <Fade { ...TransitionProps }>
              <Paper 
                className={ classes.itemList }
                style={ customStyles?.itemList }
              >
                { selecteable.map((item) => {
                  return <SearchListItemPlace place={ item } onChange={ onFilterListChange } key={ item.id } selected={ false } />;
                })}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default withCustomStyles("generic/search-dropdown-places")(withStyles(styles, { withTheme: true })( SearchDropDownPlaces ));
