import { EventApi, FilterApi, ImageApi, LanguageApi, SearchApi, Configuration, HTTPHeaders } from "../generated/client";
import { NullableToken } from "../types";
import { Config } from "../constants/configuration"

/**
 * Utility class for loading api with predefined configuration
 *
 * TODO
 * Depending on how autentication is implemented either
 * access token or api key will be removed from this class
 */

const config = Config.getConfig();

export default class Api {

  /**
   * Gets initialized event API
   *
   * @param accessToken keycloak access token
   * @returns event API
   */
  public static getEventApi(accessToken?: NullableToken) {
    return new EventApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized filter API
   *
   * @param accessToken keycloak access token
   * @returns filter API
   */
  public static getFilterApi(accessToken?: NullableToken) {
    return new FilterApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized image API
   *
   * @param accessToken keycloak access token
   * @returns image API
   */
  public static getImageApi(accessToken?: NullableToken) {
    return new ImageApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized language API
   *
   * @param accessToken keycloak access token
   * @returns language API
   */
  public static getLanguageApi(accessToken?: NullableToken) {
    return new LanguageApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized search API
   *
   * @param accessToken keycloak access token
   * @returns search API
   */
  public static getSearchApi(accessToken?: NullableToken) {
    return new SearchApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets api configuration
   *
   * @param accessToken keycloak access token
   * @returns configuration for api
   */
  private static getConfiguration(accessToken?: NullableToken) {
    const headers: HTTPHeaders = accessToken ?
    { "Authorization": `Bearer ${ accessToken.access_token }`} :
    {};
    
    return new Configuration({
      basePath: config.apiBasePath,
      headers: headers
    });
  }

}
