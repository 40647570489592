import * as React from 'react';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import { withCustomStyles } from "../hocs/with-custom-styles";
import { withStyles, WithStyles } from "@material-ui/core";
import { NullableToken, CustomStyles } from "../../types";
import { ReduxActions, ReduxState } from "../../store";
import styles from "../../styles/generic/google-maps";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Place } from '../../generated/client';
import SingleEventMapMarker from "./single-event-google-map-marker"
import { Config } from "../../constants/configuration";

const config = Config.getConfig();

/**
 * Component properties
 */
interface Props extends WithStyles<typeof styles> {
  accessToken?: NullableToken;
  customStyles?: CustomStyles;
  place: Place;
}

/**
 * Component state
 */
interface State { }

/**
 * Single event map
 */
class SingleEventMap extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props properties
   */
  constructor(props: Props) {
    super(props);
    this.state = { }
  }

  /**
   * Component render
   */
  public render = () => {
    const mapOptions: MapOptions = {
      mapTypeControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true
    };

    const { customStyles, classes, place } = this.props;

    return (
      <div
        className={ classes.container }
        style={ customStyles?.container }
      >
        <GoogleMapReact
          draggable
          bootstrapURLKeys={{ key: config.googleMapAPIKey || "" }}
          defaultCenter={{ lat: 62.799252, lng: 22.850832 }}
          center={ this.eventCoordinates() }
          options={ mapOptions }
          defaultZoom={ 12 }
          yesIWantToUseGoogleMapApiInternals
        >
            { place.position.coordinates && <SingleEventMapMarker lat={ place.position.coordinates[0] } lng={ place.position.coordinates[1] }/> }
        </GoogleMapReact>
      </div>
    );
  }

  /**
   * Gets event coordinates
   */
  private eventCoordinates = () => {
    const { place } = this.props;

    if (!place?.position.coordinates) {
      return;
    }

    const coordinates = {
      lat: place.position.coordinates[0],
      lng: place.position.coordinates[1]
    }
    return coordinates;
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({});

const Styled = withStyles(styles)(SingleEventMap);
const CustomStyled = withCustomStyles("generic/single-event-map")(Styled);
const Connected = connect(mapStateToProps, mapDispatchToProps)(CustomStyled);

export default Connected;
